<template>
  <div class="row animated fadeIn">
    <div class="col-lg-12  d-flex flex-column">
      <WButton info
        customClass="ml-auto"
        v-if="!isDisable"
        @click="openProfileModal(true)"
      >
        {{ $t('portal.add') }}
      </WButton>

<!--      <Table-pagination-v2-->
<!--        :on-submit="getWithQuery.bind(this)"-->
<!--        v-model="filters"-->
<!--        :show-filters="['search', 'sort', 'limit']"-->
<!--        :options="[{ value: 'name', label: $t('general.name') }]"-->
<!--      />-->
      <Table-pagination-v3
        v-if="showTablePaginationV3Component"
        small-width-location-selector
        small-height-for-custom-multiselect-and-select-components
        :on-submit="getWithQuery.bind(this)"
        v-model="filters"
        :show-filters="['search', 'sort', 'limit', 'location']"
        :options="[{ value: 'name', label: $t('general.name') }]"
      />
      <div class="row">
              <div class="col-lg-4">
                <div class="filters__multiple">
                  <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
                    <WButton secondary sm
                      v-if="isFiltersActive"
                      @click.stop.prevent="resetAllFilters"
                    >
                      <i class="fa fa-close"></i>
                      {{ $t('general.resetAllFilters') }}
                    </WButton>
                  </div>
                </div>
              </div>

      </div>
    </div>
    <div class="col-12 animated fadeIn" v-if="dataIteratorView === true">
      <br />
      <div class="card-block p-0">
        <i class="glyphicons glyphicons-modal-window glyphicons--shrink mr-0"></i>
        {{ $t('portal.portalprofiles') }}
        <span class="text-muted ml-3" v-if="portalProfilesList.length">
          {{ this.portalProfilesList.length }} {{ this.$t('portal.paginationOf') }}
          {{ this.totalResultPortalProfiles }}
        </span>
      </div>
      <br />
      <div class="row">
        <div class="col-12">
          <!--          <div v-if="isPortalProfilesLoading" class="loader loader-backdrop loader-backdrop-portalads" />-->
          <div
            v-if="
              isPortalProfilesLoading ||
              isCpeLoading ||
              isWlansLoading ||
              isPortalAdsLoading ||
              isPortalPaymentSystemsLoading ||
              isAuthenticationsLoading ||
              isAuthorizationsLoading
            "
          >
            <Loader-spinner></Loader-spinner>
          </div>
          <div class="row">
            <Data-iterator-card
              justify-content-start
              v-for="profile in portalProfilesList"
              :title="profile.name"
              :key="profile.id"
              class="col-sm-6 col-md-4 col-lg-3 col-12"
            >
              <template #header-icons>
                <span
                  class="icon-button-hover px-q d-flex align-items-center"
                  @click="openProfileModal(false, profile)"
                  v-tooltip="{ content: $t('portalProfiles.profileEdit') }"
                >
                  <i class="fa fa-lg fa-pencil-square-o" />
                </span>
              </template>
              <template #card-body>
                <div>
                  <div>
                    <div>
                      <div class="data-iterator-data-categories">{{ $t('general.description') }}:</div>
                      <div
                        class="custom-padding-for-in-iterator-card-data-items custom-padding-bottom-for-description"
                        v-if="profile.description"
                      >
                        <span>{{ profile.description }}</span>
                      </div>
                      <div
                        class="custom-padding-for-in-iterator-card-data-items custom-padding-bottom-for-description"
                        v-else
                      >
                        {{ $t('portalProfiles.noDescription') }}
                      </div>
                    </div>
                    <!--                    <div v-if="!disableCpeWlanInInterface && profile.condition.cpe.length > 0">-->
                    <!--                      <div class="data-iterator-data-categories">{{ $t('general.cpes') }}:</div>-->
                    <!--                      <div class="custom-padding-for-in-iterator-card-data-items">-->
                    <!--                        <h5 v-if="profile.condition.cpe.length > 0">-->
                    <!--                          <span class="badge badge-success font-weight-normal" v-if="profile.condition.cpe.length > 0">-->
                    <!--                            {{ getCpeName(profile.condition.cpe[0]) }}-->
                    <!--                          </span>-->
                    <!--                          <span-->
                    <!--                            class="badge badge-dark font-weight-normal"-->
                    <!--                            v-if="profile.condition.cpe.length > 1"-->
                    <!--                            v-tooltip.top-center="{-->
                    <!--                              content: showAllCpes(profile.condition.cpe)-->
                    <!--                            }"-->
                    <!--                          >-->
                    <!--                            +{{ profile.condition.cpe.length - 1 }}-->
                    <!--                          </span>-->
                    <!--                        </h5>-->
                    <!--                        <div v-else>{{ $t('portalProfiles.noCPEs') }}</div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div v-if="!disableCpeWlanInInterface && profile.condition.wlan.length > 0">-->
                    <!--                      <div class="data-iterator-data-categories">{{ $t('general.wlans') }}:</div>-->
                    <!--                      <div class="custom-padding-for-in-iterator-card-data-items">-->
                    <!--                        <h5 v-if="profile.condition.wlan.length > 0">-->
                    <!--                          <span class="badge badge-warning font-weight-normal" v-if="profile.condition.cpe.length > 0">-->
                    <!--                            {{ getWlanName(profile.condition.wlan[0]) }}-->
                    <!--                          </span>-->
                    <!--                          <span-->
                    <!--                            class="badge badge-dark font-weight-normal"-->
                    <!--                            v-if="profile.condition.wlan.length > 1"-->
                    <!--                            v-tooltip.top-center="{-->
                    <!--                              content: showAllWlans(profile.condition.wlan)-->
                    <!--                            }"-->
                    <!--                          >-->
                    <!--                            +{{ profile.condition.wlan.length - 1 }}-->
                    <!--                          </span>-->
                    <!--                        </h5>-->
                    <!--                        <div v-else>{{ $t('portalProfiles.noWLANs') }}</div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div v-if="profile.condition.nas_id.length > 0">-->
                    <!--                      <div class="data-iterator-data-categories">{{ $t('portalProfiles.nasForTable') }}:</div>-->
                    <!--                      <div class="custom-padding-for-in-iterator-card-data-items">-->
                    <!--                        <h5 v-if="profile.condition.nas_id.length > 0">-->
                    <!--                          <span class="badge badge-warning font-weight-normal" v-if="profile.condition.cpe.length > 0">-->
                    <!--                            {{ profile.condition.nas_id[0] }}-->
                    <!--                          </span>-->
                    <!--                          <span-->
                    <!--                            class="badge badge-dark font-weight-normal"-->
                    <!--                            v-if="profile.condition.nas_id.length > 1"-->
                    <!--                            v-tooltip.top-center="{-->
                    <!--                              content: showAllNAS(profile.condition.nas_id)-->
                    <!--                            }"-->
                    <!--                          >-->
                    <!--                            +{{ profile.condition.nas_id.length - 1 }}-->
                    <!--                          </span>-->
                    <!--                        </h5>-->
                    <!--                        <div v-else>{{ $t('portalProfiles.noNAS') }}</div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <div v-if="Object.keys(profile.authentications).length">
                      <div class="data-iterator-data-categories">{{ $t('portal.authentication') }}:</div>
                      <div class="custom-padding-for-in-iterator-card-data-items">
                        <h5 v-if="Object.keys(profile.authentications).length">
                          <span
                            class="badge badge-info font-weight-normal m-q"
                            v-for="item in profile.authentications"
                            :key="item.name"
                            v-tooltip.top-center="{
                              content: showAllAuthentications(item)
                            }"
                          >
                            {{ item.type }}
                          </span>
                        </h5>
                        <div v-else>{{ $t('portalProfiles.noAuthentication') }}</div>
                      </div>
                    </div>
                    <div v-if="Object.keys(profile.authorizations).length">
                      <div class="data-iterator-data-categories">{{ $t('portal.authorization') }}:</div>
                      <div class="custom-padding-for-in-iterator-card-data-items">
                        <h5 v-if="Object.keys(profile.authorizations).length">
<!--                          <span-->
<!--                            class="badge badge-info font-weight-normal m-q"-->
<!--                            v-for="item in profile.authorizations"-->
<!--                            :key="item.name"-->
<!--                            v-tooltip.top-center="{-->
<!--                              content: showAllAuthorizations(item)-->
<!--                            }"-->
<!--                          >-->
<!--                            {{ item.type }}-->
<!--                          </span>-->
                          <span
                            class="badge badge-info font-weight-normal m-q"
                            v-for="item in profile.authorizations"
                            :key="item.name"
                            v-tooltip.top-center="{
                              content: showAllAuthorizations(item)
                            }"
                          >
                            {{ $te(`pageprofile.${item.type }`) ? $t(`pageprofile.${item.type }`) : item.type  }}
                          </span>
                        </h5>
                        <div v-else>{{ $t('portalProfiles.noAuthorization') }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #card-footer></template>
            </Data-iterator-card>
          </div>
          <div
            v-if="
              this.canLoadMorePortalProfiles &&
              this.portalProfilesList.length &&
              this.totalResultPortalProfiles - this.portalProfilesList.length
            "
            class="card-block text-center p-0"
          >
            <WButton fullWidth
              @click="loadMore"
            >
              {{ this.$t('portal.paginationLoad') }}
              <span v-if="this.filters.limit < this.totalResultPortalProfiles - this.portalProfilesList.length">
                {{ this.filters.limit }}
              </span>
              <span v-else>{{ this.totalResultPortalProfiles - this.portalProfilesList.length }}</span>
              {{ this.$t('portal.paginationProfilesOutOf') }}
              {{ this.totalResultPortalProfiles - this.portalProfilesList.length }}
            </WButton>
          </div>
        </div>
      </div>
    </div>
    <!--    таблица дальше исключительно раньше была вместо карточек, теперь в целях отладки-->
    <div class="col-lg-12" v-else>
      <div class="card">
        <!--        <div class="loader loader-backdrop loader-backdrop-portalprofiles" v-if="isPortalProfilesLoading"></div>-->
        <div v-if="isPortalProfilesLoading"><Loader-spinner /></div>
        <div class="card-header">
          <i class="glyphicons glyphicons-modal-window glyphicons--shrink mr-0"></i>
          {{ $t('portal.portalprofiles') }}
          <span class="text-muted ml-3" v-if="portalProfilesList.length">
            {{ this.portalProfilesList.length }} {{ this.$t('portal.paginationOf') }}
            {{ this.totalResultPortalProfiles }}
          </span>
        </div>

        <div class="card-block card-block__table table-responsive">
          <!-- <h5 class="mb-1"></h5> -->
          <!-- <div class="row">
          <div class="col-lg-12">-->
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col" class="text-nowrap align-middle">{{ $t('general.name') }}</th>
                <!--                <th scope="col" class="text-nowrap align-middle">{{ $t('general.location') }}</th>-->
                <th scope="col" class="text-nowrap align-middle">{{ $t('general.description') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('general.cpes') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('general.wlans') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('portalProfiles.nasForTable') }}</th>
                <!--                <th scope="col" class="text-nowrap align-middle">-->
                <!--                  {{ $t('portal.code') }} / {{ $t('portal.prefix') }}-->
                <!--                </th>-->
                <th scope="col" class="text-nowrap align-middle">{{ $t('portal.authentication') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('portal.authorization') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="profile.id" v-for="profile in portalProfilesList" @click="openProfileModal(false, profile)">
                <td class="align-middle">
                  <span v-if="profile.name">
                    {{ profile.name }}
                  </span>
                  <Empty-cell-value v-else />
                </td>
                <!--                <td class="align-middle">{{ getLocationNameByProfileId(profile.id) }}</td>-->
                <td class="align-middle">{{ profile.description }}</td>
                <td class="align-middle">
                  <h5 v-if="profile.condition.cpe.length > 0">
                    <span class="badge badge-success font-weight-normal" v-if="profile.condition.cpe.length > 0">
                      {{ getCpeName(profile.condition.cpe[0]) }}
                    </span>
                    <span
                      class="badge badge-dark font-weight-normal"
                      v-if="profile.condition.cpe.length > 1"
                      v-tooltip.top-center="{
                        content: showAllCpes(profile.condition.cpe)
                      }"
                    >
                      +{{ profile.condition.cpe.length - 1 }}
                    </span>
                  </h5>
                  <Empty-cell-value v-else />
                </td>
                <td class="align-middle">
                  <h5 v-if="profile.condition.wlan.length > 0">
                    <span class="badge badge-warning font-weight-normal" v-if="profile.condition.cpe.length > 0">
                      {{ getWlanName(profile.condition.wlan[0]) }}
                    </span>
                    <span
                      class="badge badge-dark font-weight-normal"
                      v-if="profile.condition.wlan.length > 1"
                      v-tooltip.top-center="{
                        content: showAllWlans(profile.condition.wlan)
                      }"
                    >
                      +{{ profile.condition.wlan.length - 1 }}
                    </span>
                  </h5>
                  <Empty-cell-value v-else />
                </td>

                <!--                <td class="align-middle">-->
                <!--                  <h5 v-if="Object.keys(profile.msisdn_config.prefix).length">-->
                <!--                    <span-->
                <!--                      class="badge badge-dark font-weight-normal"-->
                <!--                      v-for="(code, key) in profile.msisdn_config.prefix"-->
                <!--                      :key="key"-->
                <!--                      v-tooltip.top-center="{-->
                <!--                        content: showAllPrefix(code, key)-->
                <!--                      }"-->
                <!--                    >-->
                <!--                      +{{ key }}-->
                <!--                    </span>-->
                <!--                  </h5>-->
                <!--                  <EmptyCellValue v-else />-->
                <!--                </td>-->
                <td class="align-middle">
                  <h5 v-if="profile.condition.nas_id.length > 0">
                    <span class="badge badge-warning font-weight-normal" v-if="profile.condition.cpe.length > 0">
                      {{ profile.condition.nas_id[0] }}
                    </span>
                    <span
                      class="badge badge-dark font-weight-normal"
                      v-if="profile.condition.nas_id.length > 1"
                      v-tooltip.top-center="{
                        content: showAllNAS(profile.condition.nas_id)
                      }"
                    >
                      +{{ profile.condition.nas_id.length - 1 }}
                    </span>
                  </h5>
                  <Empty-cell-value v-else />
                </td>
                <td class="align-middle">
                  <h5 v-if="Object.keys(profile.authentications).length">
                    <span
                      class="badge badge-info font-weight-normal m-q"
                      v-for="item in profile.authentications"
                      :key="item.id"
                      v-tooltip.top-center="{
                        content: showAllAuthentications(item)
                      }"
                    >
                      {{ item.type }}
                    </span>
                  </h5>
                  <Empty-cell-value v-else />
                </td>
                <td class="align-middle">
                  <h5 v-if="Object.keys(profile.authorizations).length">
                    <span
                      class="badge badge-info font-weight-normal m-q"
                      v-for="item in profile.authorizations"
                      :key="item.id"
                      v-tooltip.top-center="{
                        content: showAllAuthorizations(item)
                      }"
                    >
                      {{ item.type }}
                    </span>
                  </h5>
                  <Empty-cell-value v-else />
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div>
          </div>-->
          <div
            v-if="
              this.canLoadMorePortalProfiles &&
              this.portalProfilesList.length &&
              this.totalResultPortalProfiles - this.portalProfilesList.length
            "
            class="card-block text-center p-0"
          >
            <WButton fullWidth
              @click="loadMore"
            >
              {{ this.$t('portal.paginationLoad') }}
              <span v-if="this.filters.limit < this.totalResultPortalProfiles - this.portalProfilesList.length">
                {{ this.filters.limit }}
              </span>
              <span v-else>{{ this.totalResultPortalProfiles - this.portalProfilesList.length }}</span>
              {{ this.$t('portal.paginationProfilesOutOf') }}
              {{ this.totalResultPortalProfiles - this.portalProfilesList.length }}
            </WButton>
          </div>
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>

    <Modal
      class="modal-info"
      v-model="isProfileModalOpen"
      effect="fade/zoom"
      xxlarge
      :backdrop="false"
      @click.native="handleModalClick"
      closeBtn
    >
      <!-- Modal header -->
      <h4 slot="modal-title" class="modal-title">
        <span>{{ isNewProfile ? $t('portal.new') : $t('portal.edit') }}</span>
      </h4>
      <div v-if="updatedProfile">
        <div class="row">
          <!--Column 1-->
          <div class="col-lg-6 col-md-12">
            <!--General-->
            <!--<h6 class="mt-2 mb-2">General</h6>-->
            <div>
              <div class="row">
                <!-- Name -->
                <div class="col-6 form-group" :class="{'col-12': !areLocationsVisible}">
                  <label for="name">{{ $t('general.name') }}</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    :placeholder="$t('general.name')"
                    v-validate="'required'"
                    :data-vv-as="$t('general.name')"
                    class="form-control input"
                    :class="{ 'is-danger': errors.has('name') }"
                    v-model="updatedProfile.name"
                    :readonly="isDisable"
                  />
                  <span
                    v-tooltip.top-center="{
                      content: errors.first('name')
                    }"
                    v-show="errors.has('name')"
                    class="help is-danger error_tooltip"
                  >
                    <i class="fa fa-warning text-error"></i>
                  </span>
                </div>
                <!-- Location -->
                <div class="col-6 form-group" v-if="areLocationsVisible">
                  <label for="location">{{ $t('general.location') }}</label>
                  <select v-model="updatedProfile.base_location" id="location" name="location" class="form-control">
                    <option v-for="location in locationsList" :key="location.id" :value="location.id">
                      {{ location.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <!-- Description -->
                <div class="col-12 form-group">
                  <label for="description">{{ $t('general.description') }}</label>
                  <input
                    type="text"
                    name="description"
                    id="description"
                    :placeholder="$t('general.description')"
                    class="form-control input"
                    :class="{ 'is-danger': errors.has('description') }"
                    v-model="updatedProfile.description"
                    :readonly="isDisable"
                  />
                  <span v-show="errors.has('description')" class="help is-danger">
                    {{ errors.first('description') }}
                  </span>
                </div>
              </div>
              <div class="row">
                <!-- Time zone -->
                <div class="col-12 form-group">
                  <label for="tz">{{ $t('general.tz') }}</label>
                  <multiselect
                    :selectLabel="$t('general.multiSelectPressToSelect')"
                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                :selectedLabel="$t('general.multiSelectSelected')"
                  :placeholder="$t('general.tz')"
                    id="tz"
                    v-model="selectedTimeZone"
                    @select="
                      (selectedOpt) => {
                        updatedProfile.utc_diff = selectedOpt.offset;
                      }
                    "
                    :options="timeZonesOptions"
                    class="input"
                    :class="{ 'is-danger': errors.has('tz'), 'multiselect-disabled': isDisable }"
                    :disabled="isDisable"
                    data-vv-name="tz"
                    :data-vv-as="$t('general.tz')"
                    label="text"
                    track-by="text"
                    :empty="false"
                  />
                  <!-- <span v-show="errors.has('tz')" class="help is-danger">{{ errors.first('tz') }}</span> -->
                  <!-- <small class="mt-h text-muted d-block" :id="'tz-caption'">{{ $t('portal.tzCaption') }}</small> -->
                </div>
              </div>
            </div>
            <div>
              <!--Condition-->
              <h6 v-if="!isCurrentUserMarketer" class="mt-2 mb-2">
                {{ $t('portalProfiles.condition') }}
                <info :content="$t('portalProfiles.conditionInfo')" />
              </h6>
              <div v-if="!isCurrentUserMarketer">
                <div class="row">
                  <!-- Wlans -->
                  <div v-if="!disableCpeWlanInInterface" class="col-6 form-group">
                    <label for="wlans">
                      {{ $t('general.wlans') }}
                      <small class="text-muted">
                        ({{ $t('general.selected') }}: {{ selectedconditionWlansCount }})
                      </small>
                    </label>
                    <a href="#" class="ml-q pull-right" @click.prevent="isWlanDescription = !isWlanDescription">
                      {{ isWlanDescription ? $t('aps.hideDescription') : $t('aps.showDescription') }}
                    </a>
                    <multiselect
                      :selectLabel="$t('general.multiSelectPressToSelect')"
                      :deselectLabel="$t('general.multiSelectDeselectLabel')"
                      :selectedLabel="$t('general.multiSelectSelected')"
                      :openDirection="'bottom'"
                      id="wlans"
                      v-model="conditionWlans"
                      :multiple="true"
                      :placeholder="$t('general.wlans')"
                      label="ssid"
                      trackBy="id"
                      :customLabel="ssidWithDescription"
                      v-validate="''"
                      :data-vv-as="$t('general.wlans')"
                      name="wlans"
                      :options="wlansList || []"
                      :hide-selected="true"
                      class="input multiselect-max-height-in-profiles-modal"
                      :class="{ 'is-danger': errors.has('wlans') }"
                      :disabled="isDisable"
                    ></multiselect>
                    <span
                      v-tooltip.top-center="{
                        content: errors.first('wlans')
                      }"
                      v-show="errors.has('wlans')"
                      class="help is-danger error_tooltip"
                    >
                      <i class="fa fa-warning text-error"></i>
                    </span>
                  </div>
                  <!--                  &lt;!&ndash; CPEs &ndash;&gt;-->
                  <!--                  <div v-if="!disableCpeWlanInInterface" class="col-6 form-group">-->
                  <!--                    <label for="cpes">{{ $t('general.cpes') }} <small class="text-muted">({{$t('general.selected')}}: {{selectedconditionCpesCount}})</small></label>-->
                  <!--                    <multiselect-->
                  <!--                      :openDirection = '"bottom"'-->
                  <!--                      id="cpes"-->
                  <!--                      v-model="conditionCpes"-->
                  <!--                      :multiple="true"-->
                  <!--                      :placeholder="$t('general.cpes')"-->
                  <!--                      label="name"-->
                  <!--                      trackBy="id"-->
                  <!--                      :options="cpesList || []"-->
                  <!--                      :hide-selected="true"-->
                  <!--                      v-validate="''"-->
                  <!--                      :data-vv-as="$t('general.cpes')"-->
                  <!--                      name="cpes"-->
                  <!--                      class="input multiselect-max-height-in-profiles-modal"-->
                  <!--                      :class="{ 'is-danger': errors.has('cpes') }"-->
                  <!--                      :disabled="isDisable"-->
                  <!--                    ></multiselect>-->
                  <!--                    <span-->
                  <!--                      v-tooltip.top-center="{-->
                  <!--                        content: errors.first('cpes')-->
                  <!--                      }"-->
                  <!--                      v-show="errors.has('cpes')"-->
                  <!--                      class="help is-danger error_tooltip"-->
                  <!--                    >-->
                  <!--                      <i class="fa fa-warning text-error"></i>-->
                  <!--                    </span>-->
                  <!--                  </div>-->
                  <!-- CPEs -->
                  <div v-if="!disableCpeWlanInInterface" class="col-6 form-group">
                    <label for="cpes">
                      {{ $t('general.cpes') }}
                      <small class="text-muted">({{ $t('general.selected') }}: {{ selectedconditionCpesCount }})</small>
                    </label>
                    <a href="#" class="ml-q pull-right" @click.prevent="toggleShowingDescriptionInCpesFilter">
                      {{
                        showDescriptionInCpesFilter ? $t('marketing.hideDescription') : $t('marketing.showDescription')
                      }}
                    </a>
                    <!--                    <div style="text-align: right">-->
                    <!--                      <a href="#" class="ml-q cpes-show-description"-->
                    <!--                         @click.prevent="toggleShowingDescriptionInCpesFilter">-->
                    <!--                        {{ showDescriptionInCpesFilter ? $t('marketing.hideDescription') :-->
                    <!--                        $t('marketing.showDescription') }}-->
                    <!--                      </a>-->
                    <!--                    </div>-->
                    <multiselect
                      :selectLabel="$t('general.multiSelectPressToSelect')"
                      :deselectLabel="$t('general.multiSelectDeselectLabel')"
                      :selectedLabel="$t('general.multiSelectSelected')"
                      @input="changeCPEsInMultiselectInModal"
                      :openDirection="'bottom'"
                      id="cpes"
                      v-model="conditionCpes"
                      :multiple="true"
                      :placeholder="$t('general.cpes')"
                      label="name"
                      trackBy="id"
                      :options="cpesListForCPEMultiselect || []"
                      :hide-selected="true"
                      v-validate="''"
                      :data-vv-as="$t('general.cpes')"
                      name="cpes"
                      class="input multiselect-max-height-in-profiles-modal"
                      :class="{ 'is-danger': errors.has('cpes') }"
                      :disabled="isDisable"
                    >
                      <template slot="option" slot-scope="props">
                        <div class="option__desc" v-if="!showDescriptionInCpesFilter">
                          <span
                            class="badge-cpe badge-status ml-h tooltip-text"
                            :class="{
                              'badge-status__connected': props.option.connected,
                              'badge-status__disconnected': !props.option.connected
                            }"
                            v-tooltip.top-center="{
                              content: `<ul class='list-unstyled text-left m-0'>
                                          <li><small><b>id</b>: ${props.option.id}</small></li>
                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>
                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>
                                        </ul>`
                            }"
                          >
                            <span v-if="props.option.name">{{ props.option.name }}</span>
                            <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                          </span>
                        </div>
                        <div class="option__desc" v-if="showDescriptionInCpesFilter">
                          <span
                            class="badge-cpe badge-status ml-h tooltip-text"
                            :class="{
                              'badge-status__connected': props.option.connected,
                              'badge-status__disconnected': !props.option.connected
                            }"
                            v-tooltip.top-center="{
                              content: `<ul class='list-unstyled text-left m-0'>
                                          <li><small><b>id</b>: ${props.option.id}</small></li>
                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>
                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>
                                          <li><small>${$t('general.description')}: <b></b> ${
                                props.option.description ? props.option.description : $t('general.no').toLowerCase()
                              }</small></li>
                                        </ul>`
                            }"
                          >
                            <template v-if="props.option.name">
                              <span v-if="!props.option.description">
                                {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}
                              </span>
                              <span v-if="props.option.description">
                                {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}:
                                {{ cutOffLongTextForCPEDescription(props.option.description) }}
                              </span>
                            </template>
                            <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                          </span>
                        </div>
                      </template>
                    </multiselect>
                    <span
                      v-tooltip.top-center="{
                        content: errors.first('cpes')
                      }"
                      v-show="errors.has('cpes')"
                      class="help is-danger error_tooltip"
                    >
                      <i class="fa fa-warning text-error"></i>
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 form-group">
                    <!-- NAS ID -->
                    <AddList
                      name="nas-id"
                      :list="updatedProfile.condition.nas_id"
                      :title="$t('portal.nasId')"
                      :validate="'min:3|max:64'"
                      :placeholder="'NAS ID'"
                      :typeList="'array'"
                      :class="{ 'is-danger': errors.has('nas-id') }"
                    />
                  </div>
                  <!-- loc id -->
                  <div class="col-6 form-group" v-if="areLocationsVisible">
                    <label for="locids">{{ $t('general.locations') }}</label>
                    <Multi-select-component
                      v-if="conditionLocIdShowingBlock"
                      :in-modal="true"
                      class="w-100"
                      option-id-name="id"
                      track-by="name"
                      :placeholder="$t('general.locations')"
                      :disabled="isDisable"
                      :async-function="requestLocations.bind(this)"
                      v-model="conditionLocId"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <!--Access-->
              <h6 class="mt-2 mb-2">
                {{ $t('portalProfiles.access') }}
                <info :content="$t('portalProfiles.accessInfo')" />
              </h6>
              <div>
                <!-- Country code -->
                <div class="row">
                  <div class="col-6 form-group">
                    <label>{{ $t('portal.code') }} + {{ $t('portal.prefix') }}</label>
                    <div class="input-group">
                      <select id="type" name="type" v-model="newPhoneCode" class="form-control" :disabled="isDisable">
                        <option value disabled selected>{{$t('portalProfiles.countryCode')}}</option>
                        <option v-for="code in phoneCodes" :value="code" :key="code.code">
                          {{ code.name }}: {{ code.dial_code }}
                        </option>
                      </select>
                      <input
                        type="text"
                        @keyup.13="addPrefix(newPhoneCode.dial_code, newPrefix)"
                        name="prefix"
                        class="form-control input prefix-optional-in-portal-profiles"
                        :placeholder="$t('portalProfiles.phonePrefixOptional')"
                        :disabled="isDisable"
                        v-validate="'numeric'"
                        data-vv-validate-on="input"
                        data-vv-as="Prefix"
                        v-model="newPrefix"
                        :class="{ 'is-danger': errors.has('prefix') }"
                      />
                      <WButton success
                        @click="addPrefix(newPhoneCode.dial_code, newPrefix)"
                        :disabled="newPhoneCode === ''"
                      >
                        {{ $t('general.add') }}
                      </WButton>
                    </div>
                    <span
                      v-tooltip.top-center="{
                        content: errors.first('prefix')
                      }"
                      v-show="errors.has('prefix')"
                      class="help is-danger error_tooltip"
                    >
                      <i class="fa fa-warning text-error"></i>
                    </span>
                    <!-- Prefix -->
                    <div class>
                      <ul class="list-unstyled ml-h">
                        <li :key="key" v-for="(code, key) in updatedProfile.msisdn_config.prefix">
                          +{{ key }}:
                          <span v-if="updatedProfile.msisdn_config.prefix[key].length">
                            <span
                              class="text-muted link"
                              :key="prefix"
                              v-for="prefix in updatedProfile.msisdn_config.prefix[key]"
                            >
                              <a href="#" class="text-muted ml-h" @click="removePrefix(key, prefix)">
                                {{ prefix }}
                                <i v-if="!isDisable" class="fa fa-remove text-danger ml-q"></i>
                              </a>
                            </span>
                          </span>
                          <span v-else class="text-muted">
                            <a href="#" class="text-muted ml-h" @click="removePrefix(key)">
                              {{ $t('portal.allPrefix') }}
                              <i v-if="!isDisable" class="fa fa-remove text-danger mr-q"></i>
                            </a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!--                  &lt;!&ndash; Accesslist Old &ndash;&gt;-->
                  <!--                  <AddList-->
                  <!--                    name="mac-accesslist"-->
                  <!--                    :list="updatedProfile.access_list"-->
                  <!--                    :title="$t('portal.accessList')"-->
                  <!--                    :validate="'mac'"-->
                  <!--                    :placeholder="'aa:aa:aa:aa:aa:aa'"-->
                  <!--                    :typeList="'Object'"-->
                  <!--                    class="half-width"-->
                  <!--                    :class="{ 'is-danger': errors.has('mac-access') }"-->
                  <!--                  />-->
                  <!-- Accesslist -->
                  <div class="col-6 form-group">
                    <label>{{ $t('portal.accessList') }}</label>
                    <!--                      <div>{{updatedProfileAccessListLength}}</div>-->
                    <h5 class="row m-0">
                      <span
                        role="button"
                        class="col-10 badge badge-success font-weight-normal counter-access-list-in-portal-profiles"
                        v-if="updatedProfileAccessListLength > 0"
                        @click="goToAccountsWithIdsList(updatedProfileAccessListAsIdsArray)"
                      >
                        {{ $t('portal.thereAreUsersInList') }}
                      </span>
                      <span
                        class="col-10 badge badge-warning font-weight-normal counter-access-list-in-portal-profiles"
                        v-if="updatedProfileAccessListLength === 0"
                      >
                        {{ $t('portal.noUsersInList') }}
                      </span>
                      <span
                        role="button"
                        class="col-2 badge badge-dark font-weight-normal counter-access-list-in-portal-profiles text-center"
                        v-if="updatedProfileAccessListLength > 0"
                        @click="goToAccountsWithIdsList(updatedProfileAccessListAsIdsArray)"
                      >
                        {{ updatedProfileAccessListLength }}
                      </span>
                      <span
                        class="col-2 badge badge-dark font-weight-normal counter-access-list-in-portal-profiles text-center"
                        v-if="updatedProfileAccessListLength === 0"
                      >
                        0
                      </span>
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <!-- Blacklist -->
                  <div class="col-6 form-group">
                    <AddList
                      name="mac-blacklist"
                      :list="updatedProfile.black_list"
                      :title="$t('portal.blackList')"
                      :validate="'mac'"
                      :placeholder="'aa:aa:aa:aa:aa:aa'"
                      :typeList="'Object'"
                      :class="{ 'is-danger': errors.has('mac-blacklist') }"
                    />
                  </div>
                  <!-- WhiteList -->
                  <div class="col-6 form-group">
                    <AddList
                      name="mac-whitelist"
                      :list="updatedProfile.white_list"
                      :title="$t('portal.whiteList')"
                      :validate="'mac'"
                      :placeholder="'aa:aa:aa:aa:aa:aa'"
                      :typeList="'Object'"
                      :class="{ 'is-danger': errors.has('mac-whitelist') }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mt-2 mb-2">
                {{$t('portalProfiles.lineCabinetCaption')}}
              </h6>
              <div class="ml-0 mt-h">
                  <Switch-component
                    v-model="updatedProfile.enable_line_cabinet"
                    :disabled="isDisable"
                    :label="$t('portalProfiles.lineCabinetEnableSwitcher')"
                  />
                </div>
              <transition name="fade">
                <div v-if="updatedProfile.enable_line_cabinet && !isNewProfile" class="ml-0 mt-h">
                  <div class="ml-0">
                    <div class="ml-0"><b>{{$t('portalProfiles.lineCabinetAddressCaption')}}:</b>
                    </div>
                    <a :href="lkUrl" target="_blank" class="ml-0 mr-q">
                      <span>{{lkUrl}}</span>

                    </a>
                    <i
                      v-tooltip="{ content: $t('general.copyToClipBoardCaption') }"
                      @click.stop="
                                    copyToClipboard(lkUrl)
                                  "
                      class="fa fa-clipboard icon-clipboard"
                    ></i>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <!--Column 2-->
          <div class="col-lg-6 col-md-12">
            <!-- Authentication -->
            <div v-if="!isBeelineBranding" class="d-flex justify-content-between align-items-center flex-wrap">
              <h6 class="mt-2 mb-2">
                {{ $t('portal.authentication') }}
                <info :content="$t('portalProfiles.authenInfo')" />
              </h6>
              <div
                v-if="isSelectorForAuthNSameSettingsShown === true"
                class="d-flex justify-content-between align-items-center"
              >
                <div>
                  <span>{{ $t('portalProfiles.sameAsForAuthN') }}</span>
                </div>
                <div class="ml-1">
                  <select @change="sameAsSelectedForAuthN" class="form-control">
                    <option :value="'none'"></option>
                    <option v-for="profile in portalProfilesList" :key="profile.id" :value="profile.id">
                      {{ profile.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!--            authn_skip-->
            <div v-if="!isBeelineBranding" class="d-flex justify-content-start align-items-center flex-wrap mb-1">
              <div class="d-flex justify-content-start align-items-start w-100 flex-column">
                <!--                <div>-->
                <!--                  <span class="default-authZ-caption">{{$t('portalProfiles.authzDefaultCaption')}}: </span>-->
                <!--                </div>-->
                <div class="ml-0 mt-h">
                  <Switch-component
                    v-model="updatedProfile.authn_skip"
                    :disabled="isDisable"
                    :label="$t('portalProfiles.authnSkipCaption')"
                    @input="authNSkipSwitched"
                  />
                </div>
              </div>
            </div>

            <!--              general authN settings-->
            <div v-if="!isBeelineBranding" class="mb-1">
              <div class="d-flex justify-content-start align-items-start w-100 flex-column">
                <h6
                  @click="toggleGeneralAuthNSettingsBlock"
                  :class="{ 'authentication-general-settings-in-portal-profiles-locked': isAllAuhNsSwitchersLocked }"
                >
                  <i v-if="isGeneralAuthNSettingsBlockOpened" class="icon-arrow-down"></i>
                  <i v-else class="icon-arrow-right"></i>
                  {{ $t('portalProfiles.generalSettingsForAllAuthNCaption') }}
                  <info :content="$t(`portalProfiles.generalSettingsForAllAuthNInfoMsg`)" />
                </h6>
              </div>
              <div class="ml-2" v-if="isGeneralAuthNSettingsBlockOpened === true">
                <div class="col-12">
                  <div class="row">
                    <div class="col-xs-12 col-5 form-group">
                      <label for="generalAuthNSettings-remember">{{ $t('portal.remember') }}</label>
                      <input
                        type="text"
                        name="generalAuthNSettings-remember"
                        id="generalAuthNSettings-remember"
                        placeholder="In sec"
                        :readonly="isDisable"
                        class="form-control input"
                        aria-describedby="generalAuthNSettings-remember-caption`"
                        v-validate="'required|numeric'"
                        :class="{ 'is-danger': errors.has(`generalAuthNSettings-remember`) }"
                        v-model.number="generalAuthNSettings.remember"
                        size="10"
                        :data-vv-as="$t('portal.remember')"
                      />
                      <span v-show="errors.has(`generalAuthNSettings-remember`)" class="help is-danger">
                        {{ errors.first(`generalAuthNSettings-remember`) }}
                      </span>
                      <small class="mt-h text-muted d-block" id="`generalAuthNSettings-remember-caption`">
                        {{ $t('portal.rememberCaption') }}
                      </small>
                    </div>
                    <div v-if="!isDisable" class="col-xs-12 col-7 form-group">
                      <label
                        v-if="
                          !chooseIntervalShowingForAuthenticationGeneral ||
                          chooseIntervalShowingForAuthenticationGeneral === false
                        "
                      >
                        {{ $t('drPicker.chooseInterval') }}
                      </label>
                      <div
                        v-if="
                          !chooseIntervalShowingForAuthenticationGeneral ||
                          chooseIntervalShowingForAuthenticationGeneral === false
                        "
                        class="d-flex justify-content-start flex-wrap"
                      >
                        <WButton success
                          customClass="portal-profiles-interval-select-buttons"
                          @click="generalAuthNSettings.remember = moment(0).add(12, 'hours').unix()"
                        >
                          {{ $t('general.duration12h') }}
                        </WButton>
                        <WButton success
                          customClass="portal-profiles-interval-select-buttons"
                          @click="generalAuthNSettings.remember = moment(0).add(1, 'days').unix()"
                        >
                          {{ $t('general.durationDay') }}
                        </WButton>
                        <WButton success
                          customClass="portal-profiles-interval-select-buttons"
                          @click="generalAuthNSettings.remember = moment(0).add(31, 'days').unix()"
                        >
                          {{ $t('general.durationMonth') }}
                        </WButton>
                        <WButton success
                          customClass="portal-profiles-interval-select-buttons"
                          @click="generalAuthNSettings.remember = moment(0).add(365, 'days').unix()"
                        >
                          {{ $t('general.durationYear') }}
                        </WButton>
                        <WButton success
                          customClass="portal-profiles-interval-select-buttons"
                          @click="chooseIntervalShowingForAuthenticationGeneral = true"
                        >
                          {{ $t('portalProfiles.customTimeInterval') }}
                        </WButton>
                      </div>
                      <div v-if="chooseIntervalShowingForAuthenticationGeneral === true" class="row">
                        <label for="amount-time">
                          {{ $t('portalProfiles.chooseCustomInterval') }}
                          <i
                            class="fa fa-remove text-danger ml-q"
                            role="button"
                            @click="chooseIntervalShowingForAuthenticationGeneral = false"
                          ></i>
                        </label>
                        <div v-if="chooseIntervalShowingForAuthenticationGeneral === true" class="col-12 form-group">
                          <div class="row">
                            <!-- <div class="col-12"> -->
                            <input
                              v-model="generalAuthNSettings.amount"
                              name="amount-time"
                              placeholder="Choose amount"
                              class="form-control col-4"
                              size="1"
                            />

                            <select
                              v-model="generalAuthNSettings.metrics"
                              name="metrics-time"
                              class="form-control col-4"
                              placeholder="of units"
                            >
                              <option value="seconds">{{ $t('drPicker.seconds') }}</option>
                              <option value="minutes">{{ $t('drPicker.minutes') }}</option>
                              <option value="hours">{{ $t('drPicker.hours') }}</option>
                              <option value="days">{{ $t('drPicker.days') }}</option>
                              <option value="weeks">{{ $t('drPicker.weeks') }}</option>
                              <option value="months">{{ $t('drPicker.months') }}</option>
                              <option value="years">{{ $t('drPicker.years') }}</option>
                            </select>
                            <WButton success
                              customClass="portal-profiles-interval-select-buttons"
                              @click="handleCustomTimeAuthenticationForGeneralSettings"
                            >
                              {{ $t('drPicker.apply') }}
                            </WButton>
                            <!-- </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="ml-1 btn-group">
                      <WButton success
                        customClass="portal-profiles-interval-select-buttons"
                        @click="applyGeneralAuthNSettings"
                      >
                        {{ $t('portalProfiles.generalSettingsForAllAuthNApplyButton') }}
                      </WButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isBeelineBranding">
              <div
                id="checkAuthNsWhenAuthenSkipIsFalseError"
                class="mb-1 validatorErrors"
                v-if="hasCheckAuthNsWhenAuthenSkipIsFalseError"
              >
                <span>{{ this.checkAuthNsWhenAuthenSkipIsFalseError }}</span>
              </div>
              <div
                class="interface-block mb-1"
                :class="{ 'authentication-list-in-portal-profiles-locked': isAllAuhNsSwitchersLocked }"
              >
                <div
                  v-for="(authentication, key) in portalAuthenticationsList"
                  :key="key"
                  v-if="isShowThisAuthenticationType(key)"
                  class="authentication-block mx-1"
                >
                  <div class="form-group mt-1 mb-q">
                    <Switch-component
                      v-model="currentAuthentications[key]"
                      :disabled="isDisable || authNDisable(key) || isAllAuhNsSwitchersLocked"
                      :label="$t(`portalProfiles.authenType${key}`) ? $t(`portalProfiles.authenType${key}`) : key"
                      :id="key"
                      @input="toggleAuthentication(key)"
                    />

                    <info class="float-right" :content="$t(`portalProfiles.${key}Info`)" />
                    <div class="div-for-settings-button-for-authn-and-authz">
                      <span
                        v-if="currentAuthentications[key]"
                        @click="toggleAuthenticationItemVisability(key)"
                        class="settings-button-for-authn-and-authz"
                        :class="{
                          'authentication-list-in-portal-profiles-locked-settings-button-for-authn': isAllAuhNsSwitchersLocked
                        }"
                      >
                        <i v-if="openedAuthentications[key]" class="icon-arrow-down"></i>
                        <i v-else class="icon-arrow-right"></i>
                        {{ $t('portalProfiles.settingsForAuthZandAuthN') }}
                      </span>
                    </div>
                  </div>
                  <div v-if="currentAuthentications[authentication.type]">
                    <div v-show="openedAuthentications[authentication.type]">
                      <div class="col-12">
                        <div
                          v-if="Object.prototype.hasOwnProperty.call(currentAuthentications[key], 'portal_http_request')"
                          class="row w-100 m-0 p-0 ml-0">
                          <div class="col-12 form-group mt-1 pr-0">
                            <PortalProfileGatewaySettings @hasNoErrors="hasNoErrorsProfileGatewaySettingsHandler"  @hasErrors="hasErrorsProfileGatewaySettingsHandler" :authNTypeName="authentication.type"
                                                          :portal_request="currentAuthentications[key].portal_http_request">

                            </PortalProfileGatewaySettings>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-xs-12 col-5 form-group">
                            <label :for="`${key}-remember`">{{ $t('portal.remember') }}</label>
                            <input
                              type="text"
                              :name="`${key}-remember`"
                              :id="`${key}-remember`"
                              placeholder="In sec"
                              :readonly="isDisable"
                              class="form-control input"
                              :aria-describedby="`${key}-remember-caption`"
                              v-validate="'required|numeric'"
                              :class="{ 'is-danger': errors.has(`${key}-remember`) }"
                              v-model.number="currentAuthentications[authentication.type].remember"
                              size="10"
                            />
                            <span v-show="errors.has(`${key}-remember`)" class="help is-danger">
                              {{ errors.first(`${key}-remember`) }}
                            </span>
                            <small class="mt-h text-muted d-block" :id="`${key}-remember-caption`">
                              {{ $t('portal.rememberCaption') }}
                            </small>
                          </div>
                          <!--                          <div v-if="!isDisable" class="col-xs-12 col-7 form-group">-->
                          <!--                            <label-->
                          <!--                              v-if="-->
                          <!--                                !chooseIntervalShowingForAuthentication[authentication.type] ||-->
                          <!--                                chooseIntervalShowingForAuthentication[authentication.type] === false-->
                          <!--                              "-->
                          <!--                            >-->
                          <!--                              {{ $t('drPicker.chooseInterval') }}-->
                          <!--                            </label>-->
                          <!--                            <div-->
                          <!--                              v-if="-->
                          <!--                                !chooseIntervalShowingForAuthentication[authentication.type] ||-->
                          <!--                                chooseIntervalShowingForAuthentication[authentication.type] === false-->
                          <!--                              "-->
                          <!--                              class="row"-->
                          <!--                            >-->
                          <!--                              <div class="col-6">-->
                          <!--                                <button-->
                          <!--                                  class="btn btn-success btn-block"-->
                          <!--                                  type="button"-->
                          <!--                                  @click="-->
                          <!--                                    currentAuthentications[authentication.type].remember = moment(0)-->
                          <!--                                      .add(12, 'hours')-->
                          <!--                                      .unix()-->
                          <!--                                  "-->
                          <!--                                >-->
                          <!--                                  {{ $t('general.duration12h') }}-->
                          <!--                                </button>-->
                          <!--                              </div>-->
                          <!--                              <div class="col-6">-->
                          <!--                                <button-->
                          <!--                                  class="btn btn-success btn-block"-->
                          <!--                                  type="button"-->
                          <!--                                  @click="-->
                          <!--                                    currentAuthentications[authentication.type].remember = moment(0)-->
                          <!--                                      .add(1, 'days')-->
                          <!--                                      .unix()-->
                          <!--                                  "-->
                          <!--                                >-->
                          <!--                                  {{ $t('general.durationDay') }}-->
                          <!--                                </button>-->
                          <!--                              </div>-->
                          <!--                              <div class="col-6 mt-h">-->
                          <!--                                <button-->
                          <!--                                  class="btn btn-success btn-block"-->
                          <!--                                  type="button"-->
                          <!--                                  @click="-->
                          <!--                                    currentAuthentications[authentication.type].remember = moment(0)-->
                          <!--                                      .add(31, 'days')-->
                          <!--                                      .unix()-->
                          <!--                                  "-->
                          <!--                                >-->
                          <!--                                  {{ $t('general.durationMonth') }}-->
                          <!--                                </button>-->
                          <!--                              </div>-->
                          <!--                              <div class="col-6 mt-h">-->
                          <!--                                <button-->
                          <!--                                  class="btn btn-success btn-block"-->
                          <!--                                  type="button"-->
                          <!--                                  @click="-->
                          <!--                                    currentAuthentications[authentication.type].remember = moment(0)-->
                          <!--                                      .add(365, 'days')-->
                          <!--                                      .unix()-->
                          <!--                                  "-->
                          <!--                                >-->
                          <!--                                  {{ $t('general.durationYear') }}-->
                          <!--                                </button>-->
                          <!--                              </div>-->
                          <!--                              <div class="col-12 mt-h">-->
                          <!--                                <button-->
                          <!--                                  class="btn btn-success btn-block"-->
                          <!--                                  type="button"-->
                          <!--                                  @click="$set(chooseIntervalShowingForAuthentication, authentication.type, true)"-->
                          <!--                                >-->
                          <!--                                  {{ $t('portalProfiles.customTimeInterval') }}-->
                          <!--                                </button>-->
                          <!--                              </div>-->
                          <!--                            </div>-->
                          <!--                            <div-->
                          <!--                              v-if="chooseIntervalShowingForAuthentication[authentication.type] === true"-->
                          <!--                              class="row"-->
                          <!--                            >-->
                          <!--                              <label for="amount-time">-->
                          <!--                                {{ $t('portalProfiles.chooseCustomInterval') }}-->
                          <!--                                <i-->
                          <!--                                  class="fa fa-remove text-danger ml-q"-->
                          <!--                                  @click="$set(chooseIntervalShowingForAuthentication, authentication.type, false)"-->
                          <!--                                ></i>-->
                          <!--                              </label>-->
                          <!--                              <div-->
                          <!--                                v-if="chooseIntervalShowingForAuthentication[authentication.type] === true"-->
                          <!--                                class="col-12 form-group"-->
                          <!--                              >-->
                          <!--                                <div class="row">-->
                          <!--                                  &lt;!&ndash; <div class="col-12"> &ndash;&gt;-->
                          <!--                                  <input-->
                          <!--                                    v-model="currentAuthentications[authentication.type].amount"-->
                          <!--                                    name="amount-time"-->
                          <!--                                    placeholder="Choose amount"-->
                          <!--                                    class="form-control col-4"-->
                          <!--                                    size="1"-->
                          <!--                                  />-->

                          <!--                                  <select-->
                          <!--                                    v-model="currentAuthentications[authentication.type].metrics"-->
                          <!--                                    name="metrics-time"-->
                          <!--                                    class="form-control col-4"-->
                          <!--                                    placeholder="of units"-->
                          <!--                                  >-->
                          <!--                                    <option value="seconds">{{ $t('drPicker.seconds') }}</option>-->
                          <!--                                    <option value="minutes">{{ $t('drPicker.minutes') }}</option>-->
                          <!--                                    <option value="hours">{{ $t('drPicker.hours') }}</option>-->
                          <!--                                    <option value="days">{{ $t('drPicker.days') }}</option>-->
                          <!--                                    <option value="weeks">{{ $t('drPicker.weeks') }}</option>-->
                          <!--                                    <option value="months">{{ $t('drPicker.months') }}</option>-->
                          <!--                                    <option value="years">{{ $t('drPicker.years') }}</option>-->
                          <!--                                  </select>-->
                          <!--                                  <button-->
                          <!--                                    class="btn btn-success col-4"-->
                          <!--                                    @click="handleCustomTimeAuthentication(authentication.type)"-->
                          <!--                                  >-->
                          <!--                                    {{ $t('drPicker.apply') }}-->
                          <!--                                  </button>-->
                          <!--                                  &lt;!&ndash; </div> &ndash;&gt;-->
                          <!--                                </div>-->
                          <!--                              </div>-->
                          <!--                            </div>-->
                          <!--                          </div>-->

                          <!--                          interval selection buttons for AuthN-->
                          <div v-if="!isDisable" class="col-xs-12 col-7 form-group">
                            <label
                              v-if="
                                !chooseIntervalShowingForAuthentication[authentication.type] ||
                                chooseIntervalShowingForAuthentication[authentication.type] === false
                              "
                            >
                              {{ $t('drPicker.chooseInterval') }}
                            </label>
                            <div
                              v-if="
                                !chooseIntervalShowingForAuthentication[authentication.type] ||
                                chooseIntervalShowingForAuthentication[authentication.type] === false
                              "
                              class="d-flex justify-content-start flex-wrap"
                            >
                              <WButton success
                                customClass="portal-profiles-interval-select-buttons"
                                @click="
                                  currentAuthentications[authentication.type].remember = moment(0)
                                    .add(12, 'hours')
                                    .unix()
                                  "
                              >
                                {{ $t('general.duration12h') }}
                              </WButton>
                              <WButton success
                                customClass="portal-profiles-interval-select-buttons"
                                @click="currentAuthentications[authentication.type].remember = moment(0).add(1, 'days').unix()"
                              >
                                {{ $t('general.durationDay') }}
                              </WButton>
                              <WButton success
                                customClass="portal-profiles-interval-select-buttons"
                                @click="
                                  currentAuthentications[authentication.type].remember = moment(0)
                                    .add(31, 'days')
                                    .unix()
                                  "
                              >
                                {{ $t('general.durationMonth') }}
                              </WButton>
                              <WButton success
                                customClass="portal-profiles-interval-select-buttons"
                                @click="
                                  currentAuthentications[authentication.type].remember = moment(0)
                                    .add(365, 'days')
                                    .unix()
                                  "
                              >
                                {{ $t('general.durationYear') }}
                              </WButton>
                              <WButton success
                                customClass="portal-profiles-interval-select-buttons"
                                @click="$set(chooseIntervalShowingForAuthentication, authentication.type, true)"
                              >
                                {{ $t('portalProfiles.customTimeInterval') }}
                              </WButton>
                            </div>
                            <div
                              v-if="chooseIntervalShowingForAuthentication[authentication.type] === true"
                              class="row"
                            >
                              <label for="amount-time">
                                {{ $t('portalProfiles.chooseCustomInterval') }}
                                <i
                                  class="fa fa-remove text-danger ml-q"
                                  role="button"
                                  @click="$set(chooseIntervalShowingForAuthentication, authentication.type, false)"
                                ></i>
                              </label>
                              <div
                                v-if="chooseIntervalShowingForAuthentication[authentication.type] === true"
                                class="col-12 form-group"
                              >
                                <div class="row">
                                  <!-- <div class="col-12"> -->
                                  <input
                                    v-model="currentAuthentications[authentication.type].amount"
                                    name="amount-time"
                                    placeholder="Choose amount"
                                    class="form-control col-4"
                                    size="1"
                                  />

                                  <select
                                    v-model="currentAuthentications[authentication.type].metrics"
                                    name="metrics-time"
                                    class="form-control col-4"
                                    placeholder="of units"
                                  >
                                    <option value="seconds">{{ $t('drPicker.seconds') }}</option>
                                    <option value="minutes">{{ $t('drPicker.minutes') }}</option>
                                    <option value="hours">{{ $t('drPicker.hours') }}</option>
                                    <option value="days">{{ $t('drPicker.days') }}</option>
                                    <option value="weeks">{{ $t('drPicker.weeks') }}</option>
                                    <option value="months">{{ $t('drPicker.months') }}</option>
                                    <option value="years">{{ $t('drPicker.years') }}</option>
                                  </select>
                                  <WButton success
                                    customClass="portal-profiles-interval-select-buttons"
                                    @click="handleCustomTimeAuthentication(authentication.type)"
                                  >
                                    {{ $t('drPicker.apply') }}
                                  </WButton>
                                  <!-- </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-6 form-group mt-h"
                            v-if="
                              authentication.type !== 'esia' &&
                              authentication.type !== 'callfront' &&
                              authentication.type !== 'userpass'
                            "
                          >
                            <label :for="`${key}-otp_length`">{{ $t('portal.otp') }}</label>
                            <input
                              type="text"
                              :name="`${key}-otp_length`"
                              :id="`${key}-otp_length`"
                              placeholder="Length of password"
                              :readonly="isDisable"
                              class="form-control input"
                              v-validate="'required|numeric|between:4,10'"
                              :class="{ 'is-danger': errors.has(`${key}-otp_length`) }"
                              v-model.number="currentAuthentications[authentication.type].otp_length"
                              size="1"
                            />
                            <span v-show="errors.has(`${key}-otp_length`)" class="help is-danger">
                              {{ errors.first(`${key}-otp_length`) }}
                            </span>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            v-if="
                              currentAuthentications[authentication.type].validate && authentication.type === 'email'
                            "
                            class="col-lg-6 col-sm-10 form-group mt-h"
                          >
                            <label :for="`${key}-regex`">{{ $t('portal.regex') }}</label>
                            <input
                              type="text"
                              name="regex"
                              :id="`${key}-regex`"
                              :readonly="isDisable"
                              :aria-describedby="`${key}-regex-caption`"
                              class="form-control input"
                              placeholder=".*@gmail\.com"
                              v-model="currentAuthentications[authentication.type].validate.regex"
                            />
                            <small class="mt-h text-muted d-block" :id="`${key}-regex-caption`">
                              {{ $t('portal.regexCaption') }}
                            </small>
                          </div>
                          <div
                            v-if="
                              currentAuthentications[authentication.type].validate && authentication.type === 'email'
                            "
                            class="col-lg-6 col-sm-10 form-group mt-h"
                          >
                            <label :for="`${key}-error-message`">{{ $t('portal.errorMessage') }}</label>
                            <input
                              type="text"
                              name="error-message"
                              :id="`${key}-error-message`"
                              :readonly="isDisable"
                              :aria-describedby="`${key}-error-message-caption`"
                              class="form-control input"
                              v-model="currentAuthentications[authentication.type].validate.error"
                            />
                            <small class="mt-h text-muted d-block" :id="`${key}-error-message-caption`">
                              {{ $t('portal.errorMessageCaption') }}
                            </small>
                          </div>
                        </div>
                        <div class="row mb-1">
                          <!--                          hide button-->
                          <!--                          <div class="col-4">-->
                          <!--                            <button-->
                          <!--                              class="btn btn-success btn-block"-->
                          <!--                              type="button"-->
                          <!--                              @click="closeAuthenticationItem(key)"-->
                          <!--                            >-->
                          <!--                              <i class="fa fa-long-arrow-up"></i>-->
                          <!--                              {{ $t('portalProfiles.cutDownButton') }}-->
                          <!--                              <i class="fa fa-long-arrow-up"></i>-->
                          <!--                            </button>-->
                          <!--                          </div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <transition name="fade">
                  <!-- SMS code (OTP CODE) -->
                  <div v-if="isauthNEnabled && isAuthNNeedingOTPCodeEnabled && !isContentAuthFreemiumEnabled" class="row">
                    <div class="col-12 form-group">
                      <label for="otp-code-text">
                        {{ $t('portalProfiles.otpCodeText') }}
                        <info :content="$t(`portalProfiles.otpInfo`)" />
                      </label>
                      <input
                        type="text"
                        name="otp-code-text"
                        id="otp-code-text"
                        placeholder="Code: {CODE}"
                        :readonly="isDisable"
                        class="form-control input"
                        v-validate="'required|sms_code'"
                        :data-vv-as="$t('portal.otpCodeText')"
                        size="2"
                        :class="{ 'is-danger': errors.has('otp-code-text') }"
                        v-model="updatedProfile.push_text.otp"
                      />
                      <span
                        v-tooltip.top-center="{
                          content: errors.first('otp-code-text')
                        }"
                        v-show="errors.has('otp-code-text')"
                        class="help is-danger error_tooltip"
                      >
                        <i class="fa fa-warning text-error"></i>
                      </span>
                    </div>
                  </div>
                </transition>
              </div>

              <div class="tos-inherit-background">
                <!-- Terms of service -->
                <h6 class="mt-2 mb-2">
                  {{ $t('pageprofile.ToS') }}
                  <info :content="$t(`portalProfiles.tosInfo`)" />
                </h6>
                <div class="mt-2 mb-0">
                  <div class="form-group mb-q">
                    <Switch-component
                      v-model="updatedProfile.terms_of_service.enable"
                      :disabled="isDisable"
                      :label="$t('pageprofile.hasAgreement')"
                      :id="'tos-switch'"
                    />
                  </div>

                  <div v-if="updatedProfile.terms_of_service.enable" class="form-group d-flex justify-content-between">
                    <div class="form-group mt-0 mb-0">
                      <Switch-component
                        v-model="updatedProfile.terms_of_service.external"
                        :disabled="isDisable"
                        :label="$t('pageprofile.isExternalUrl')"
                        :id="'external-url-switch'"
                      />
                    </div>
                    <div v-if="!isDisable && !updatedProfile.terms_of_service.external" class="form-group mr-3 mb-0">
                      <span class="fileUpload btn btn-primary">
                        <i class="fa fa-file-image-o mr-h" />
                        <span :class="{ invisible: tosMuteUpload }">{{ $t('general.chooseFile') }}</span>
                        <span v-if="tosMuteUpload" class="loader loader--mini" />
                        <input
                          @change="uploadMediaToServer('tos')"
                          class="upload tos"
                          id="media-tos"
                          name="files"
                          type="file"
                        />
                      </span>
                      <a v-if="previewTOS" class="ml-2" target="_blank" :href="previewTOS">
                        {{ $t('paymentSystems.preview') }}
                      </a>
                    </div>
                  </div>

                  <div
                    v-if="updatedProfile.terms_of_service.enable && updatedProfile.terms_of_service.external"
                    class="form-group"
                  >
                    <label v-if="updatedProfile.terms_of_service.external" for="TOS-file-url">
                      {{ $t('pageprofile.urlToExternalTOS') }}
                    </label>
                    <label v-if="!updatedProfile.terms_of_service.external" for="TOS-file-url">
                      {{ $t('pageprofile.urlToFileTOS') }}
                    </label>
                    <input
                      :class="{ 'is-danger': errors.has('TOS-file-url') }"
                      :disabled="isDisable"
                      class="form-control form-group input"
                      id="TOS-file-url"
                      name="TOS-file-url"
                      v-model="updatedProfile.terms_of_service.file_url"
                    />
                    <span
                      v-tooltip.top-center="{ content: errors.first('TOS-file-url') }"
                      v-show="errors.has('TOS-file-url')"
                      class="help is-danger error_tooltip"
                    >
                      <i class="fa fa-warning text-error" />
                    </span>
                  </div>

                  <div v-if="updatedProfile.terms_of_service.enable" class="form-group">
                    <div class="d-flex flex-column">
                      <div class="btn-group float-left" data-toggle="button">
                        <label class="btn btn-outline-primary" :class="{ active: TOS.currentLang === 'ru' }">
                          <input type="radio" name="socialAuth" @click="TOS.currentLang = 'ru'" />
                          {{ $t('portalStats.locale.ru') }}
                        </label>
                        <label class="btn btn-outline-primary" :class="{ active: TOS.currentLang === 'en' }">
                          <input type="radio" name="socialAuth" @click="TOS.currentLang = 'en'" />
                          {{ $t('portalStats.locale.en') }}
                        </label>
                        <label class="btn btn-outline-primary" :class="{ active: TOS.currentLang === 'tg' }">
                          <input type="radio" name="socialAuth" @click="TOS.currentLang = 'tg'" />
                          {{ $t('portalStats.locale.tg') }}
                        </label>
                      </div>
                      <label for="ToS">{{ $t('pageprofile.enterAgreement') }}</label>
                    </div>

                    <textarea
                      :class="{ 'is-danger': errors.has('ToS') }"
                      :disabled="isDisable"
                      class="form-control form-group input"
                      id="ToS"
                      name="ToS"
                      rows="6"
                      v-model="TOS.text[TOS.currentLang]"
                    />
                    <span
                      v-tooltip.top-center="{ content: errors.first('ToS') }"
                      v-show="errors.has('ToS')"
                      class="help is-danger error_tooltip"
                    >
                      <i class="fa fa-warning text-error" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Switch-component
              v-model="updatedProfile.random_mac_block_page"
              :label="$t('portalProfiles.showMacSettingsView')"
            />

            <!-- Authorization -->
            <div class="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <h6 class="mt-2 mb-2">
                  {{ $t('portal.authorization') }}
                  <info :content="$t(`portalProfiles.authInfo`)" />
                </h6>
              </div>
              <div
                v-if="isSelectorForAuthZSameSettingsShown === true"
                class="d-flex justify-content-between align-items-center"
              >
                <div>
                  <span>{{ $t('portalProfiles.sameAsForAuthZ') }}</span>
                </div>
                <div class="ml-1">
                  <select @change="sameAsSelectedForAuthZ" class="form-control">
                    <option :value="'none'"></option>
                    <option v-for="profile in portalProfilesList" :key="profile.id" :value="profile.id">
                      {{ profile.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!--            default authZ-->
            <div class="d-flex justify-content-start align-items-center flex-wrap mb-1">
              <div class="d-flex justify-content-start align-items-start w-100 flex-column">
                <div>
                  <span class="default-authZ-caption">{{ $t('portalProfiles.authzDefaultCaption') }}:</span>
                </div>
                <div class="ml-0 mt-h">
                  <select v-model="updatedProfile.authz_default" class="form-control">
                    <option :value="''">{{ $t('general.no') }}</option>
                    <option
                      v-for="(authorization, key) in portalAuthorizationsList"
                      :key="authorization.name"
                      :value="authorization.name"
                    >
                      {{ $te(`pageprofile.${key}`) ? $t(`pageprofile.${key}`) : key }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!--              general authZ settings-->
            <div class="mb-1">
              <div class="d-flex justify-content-start align-items-start w-100 flex-column">
                <h6 @click="toggleGeneralAuthZSettingsBlock">
                  <i v-if="isGeneralAuthZSettingsBlockOpened" class="icon-arrow-down"></i>
                  <i v-else class="icon-arrow-right"></i>
                  {{ $t('portalProfiles.generalSettingsForAllAuthZCaption') }}
                  <info :content="$t(`portalProfiles.generalSettingsForAllAuthZInfoMsg`)" />
                </h6>
              </div>
              <div class="ml-2" v-if="isGeneralAuthZSettingsBlockOpened === true">
                <div class="col-12">
                  <div class="authorization-categories">{{ $t('portalProfiles.sessionSettings') }}:</div>
                  <div>
                    <div class="row" v-for="(translate, setting) in filteredSettings" :key="setting">
                      <div v-if="setting !== 'transferable'" class="col-xs-12 col-6 form-group">
                        <label class="authZ-settings-fields-height-hack">{{ $t(`${translate}`) }}</label>
                        <input
                          type="number"
                          :name="`authz-general-settings-${setting}`"
                          :id="`authz-general-settings-${setting}`"
                          :placeholder="$t(`${translate}`)"
                          :readonly="isDisable"
                          class="form-control input"
                          v-validate="'required|numeric'"
                          :class="{ 'is-danger': errors.has(`authz-general-settings-${setting}`) }"
                          v-model.number="generalAuthZSettings[setting].value"
                          :data-vv-as="$t(`${translate}`)"
                        />
                        <small v-if="hasCaption(translate)" class="text-muted">
                          {{ $t(`${translate}Caption`) }}
                        </small>
                      </div>
                      <div
                        v-if="setting !== 'max_sessions' && setting !== 'transferable'"
                        class="col-xs-12 col-6 form-group d-flex flex-column justify-content-start align-items-start"
                      >
                        <template
                          v-if="
                            !chooseIntervalShowingForAuthorizationsGeneral ||
                            !chooseIntervalShowingForAuthorizationsGeneral[setting] ||
                            chooseIntervalShowingForAuthorizationsGeneral[setting] === false
                          "
                        >
                          <label class="authZ-settings-fields-height-hack" for="amount-time">
                            {{ $t('drPicker.chooseInterval') }}
                          </label>
                          <div class="d-flex justify-content-start flex-wrap">
                            <WButton success
                              customClass="portal-profiles-interval-select-buttons"
                              @click="generalAuthZSettings[setting].value = moment(0).add(12, 'hours').unix()"
                            >
                              {{ $t('general.duration12h') }}
                            </WButton>
                            <WButton success
                              customClass="portal-profiles-interval-select-buttons"
                              @click="generalAuthZSettings[setting].value = moment(0).add(1, 'days').unix()"
                            >
                              {{ $t('general.durationDay') }}
                            </WButton>
                            <WButton success
                              customClass="portal-profiles-interval-select-buttons"
                              @click="generalAuthZSettings[setting].value = moment(0).add(31, 'days').unix()"
                            >
                              {{ $t('general.durationMonth') }}
                            </WButton>
                            <WButton success
                              customClass="portal-profiles-interval-select-buttons"
                              @click="generalAuthZSettings[setting].value = moment(0).add(365, 'days').unix()"
                            >
                              {{ $t('general.durationYear') }}
                            </WButton>
                            <WButton success
                              customClass="portal-profiles-interval-select-buttons"
                              @click="setChooseIntervalShowingForAuthorizationsGeneralSettings(setting)"
                            >
                              {{ $t('portalProfiles.customTimeInterval') }}
                            </WButton>
                          </div>
                        </template>
                        <template
                          v-if="
                            chooseIntervalShowingForAuthorizationsGeneral &&
                            chooseIntervalShowingForAuthorizationsGeneral[setting] &&
                            chooseIntervalShowingForAuthorizationsGeneral[setting] === true
                          "
                        >
                          <label class="authZ-settings-fields-height-hack" for="amount-time">
                            {{ $t('portalProfiles.chooseCustomInterval') }}
                            <i
                              role="button"
                              class="fa fa-remove text-danger ml-q"
                              @click="$set(chooseIntervalShowingForAuthorizations, setting, false)"
                            ></i>
                          </label>
                          <div class="col-12">
                            <div class="row">
                              <input
                                v-model="generalAuthZSettings[setting].amount"
                                name="amount-time"
                                placeholder="Choose amount"
                                class="col-4 form-control"
                                size="1"
                              />
                              <select
                                v-model="generalAuthZSettings[setting].metrics"
                                name="metrics-time"
                                class="col-4 form-control"
                                placeholder="of units"
                              >
                                <option value="seconds">{{ $t('drPicker.seconds') }}</option>
                                <option value="minutes">{{ $t('drPicker.minutes') }}</option>
                                <option value="hours">{{ $t('drPicker.hours') }}</option>
                                <option value="days">{{ $t('drPicker.days') }}</option>
                                <option value="weeks">{{ $t('drPicker.weeks') }}</option>
                                <option value="months">{{ $t('drPicker.months') }}</option>
                                <option value="years">{{ $t('drPicker.years') }}</option>
                              </select>
                              <WButton success
                                customClass="portal-profiles-interval-select-buttons"
                                @click="handleCustomTimeAuthorizationForGeneralSettings(setting)"
                              >
                                {{ $t('drPicker.apply') }}
                              </WButton>
                            </div>
                          </div>
                        </template>
                        <span v-show="errors.has(`authz-general-settings-${setting}`)" class="help is-danger">
                          {{ errors.first(`authz-general-settings-${setting}`) }}
                        </span>
                      </div>
                      <div class="col-xs-12 col-6 form-group mt-2" v-if="setting === 'transferable'">
                        <Switch-component
                          v-model="generalAuthZSettings[setting].value"
                          :disabled="isDisable"
                          :label="$t(`${translate}`)"
                          :id="`authz-general-settings-${setting}`"
                        />
                        <info class="ml-h" :content="$t('portal.transferableInfo')" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="ml-1 btn-group">
                        <WButton success
                          customClass="portal-profiles-interval-select-buttons"
                          @click="applyGeneralAuthZSettings"
                        >
                          {{ $t('portalProfiles.generalSettingsForAllAuthZApplyButton') }}
                        </WButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="interface-block mb-1">
              <div
                v-for="(authorization, key) in portalAuthorizationsList"
                :key="key"
                v-if="isShowThisAuthorizationType(key)"
                class="authorization-block mx-1"
              >
                <div class="form-group mt-1 mb-q">
                  <Switch-component
                    v-model="currentAuthorizations[key]"
                    :disabled="isDisable || authZDisable(key)"
                    :label="$te(`pageprofile.${key}`) ? $t(`pageprofile.${key}`) : key"
                    :id="key"
                    @input="toggleAuthorization(key)"
                  />
                  <info class="float-right" :content="$t(`portalProfiles.${key}Info`)" />
                  <div class="div-for-settings-button-for-authn-and-authz">
                    <span
                      v-if="currentAuthorizations[key]"
                      @click="toggleAuthorizationItemVisability(key)"
                      class="settings-button-for-authn-and-authz"
                    >
                      <i v-if="openedAuthorizations[key]" class="icon-arrow-down"></i>
                      <i v-else class="icon-arrow-right"></i>
                      {{ $t('portalProfiles.settingsForAuthZandAuthN') }}
                    </span>
                  </div>
                  <transition name="fade">
                    <small
                      class="div-for-settings-button-for-authn-and-authz badge badge-info social-auth-required-fields-not-forget-info-msg-custom-color"
                      v-if="checkIsShowningRequiredFieldsMsgForSocialNetworkAuthZ(key)"
                    >
                      * {{ $t('portalProfiles.socialAuthRequiredFieldsNotForgetToFillNotificationMsg') }}
                    </small>
                  </transition>
                  <div v-if="key === 'voucher'" style="float: right;"></div>
                </div>

                <div v-if="currentAuthorizations[key]">
                  <div v-show="openedAuthorizations[key]">
                    <!-- filteredSettings used to temporarily exclude download_limit & auth_timeout keys -->
                    <template v-if="key !== 'voucher'">
                      <div class="col-12">
                        <div class="authorization-categories">{{ $t('portalProfiles.sessionSettings') }}:</div>
                        <div>
                          <div class="row" v-for="(translate, setting) in filteredSettings" :key="setting">
                            <div v-if="setting !== 'transferable' && key !=='subscription'" class="col-xs-12 col-6 form-group">
                              <label class="authZ-settings-fields-height-hack" :for="key">
                                {{ $t(`${translate}`) }}
                              </label>
                              <input
                                type="number"
                                :name="`${key}-${setting}`"
                                :id="setting"
                                :placeholder="$t(`${translate}`)"
                                :readonly="isDisable"
                                class="form-control input"
                                v-validate="'required|numeric'"
                                :class="{ 'is-danger': errors.has(`${key}-${setting}`) }"
                                v-model.number="currentAuthorizations[key].config[setting]"
                              />
                              <small v-if="hasCaption(translate)" class="text-muted">
                                {{ $t(`${translate}Caption`) }}
                              </small>
                            </div>
                            <!--                            <div v-if="setting !== 'max_sessions'"  class="col-xs-12 col-6 form-group">-->
                            <div
                              v-if="setting !== 'max_sessions' && setting !== 'transferable' && key !=='subscription'"
                              class="col-xs-12 col-6 form-group d-flex flex-column justify-content-start align-items-start"
                            >
                              <!--                              <template-->
                              <!--                                v-if="-->
                              <!--                                  !chooseIntervalShowingForAuthorizations ||-->
                              <!--                                  !chooseIntervalShowingForAuthorizations[key] ||-->
                              <!--                                  !chooseIntervalShowingForAuthorizations[key][setting] ||-->
                              <!--                                  chooseIntervalShowingForAuthorizations[key][setting] === false-->
                              <!--                                "-->
                              <!--                              >-->
                              <!--                                <label for="amount-time">-->
                              <!--                                  {{ $t('drPicker.chooseInterval') }}-->
                              <!--                                </label>-->
                              <!--                                <div class="row">-->
                              <!--                                  <div class="col-6">-->
                              <!--                                    <button-->
                              <!--                                      class="btn btn-success btn-block"-->
                              <!--                                      type="button"-->
                              <!--                                      @click="-->
                              <!--                                        currentAuthorizations[key].config[setting] = moment(0).add(12, 'hours').unix()-->
                              <!--                                      "-->
                              <!--                                    >-->
                              <!--                                      {{ $t('general.duration12h') }}-->
                              <!--                                    </button>-->
                              <!--                                  </div>-->
                              <!--                                  <div class="col-6">-->
                              <!--                                    <button-->
                              <!--                                      class="btn btn-success btn-block"-->
                              <!--                                      type="button"-->
                              <!--                                      @click="-->
                              <!--                                        currentAuthorizations[key].config[setting] = moment(0).add(1, 'days').unix()-->
                              <!--                                      "-->
                              <!--                                    >-->
                              <!--                                      {{ $t('general.durationDay') }}-->
                              <!--                                    </button>-->
                              <!--                                  </div>-->
                              <!--                                  <div class="col-6 mt-h">-->
                              <!--                                    <button-->
                              <!--                                      class="btn btn-success btn-block"-->
                              <!--                                      type="button"-->
                              <!--                                      @click="-->
                              <!--                                        currentAuthorizations[key].config[setting] = moment(0).add(31, 'days').unix()-->
                              <!--                                      "-->
                              <!--                                    >-->
                              <!--                                      {{ $t('general.durationMonth') }}-->
                              <!--                                    </button>-->
                              <!--                                  </div>-->
                              <!--                                  <div class="col-6 mt-h">-->
                              <!--                                    <button-->
                              <!--                                      class="btn btn-success btn-block"-->
                              <!--                                      type="button"-->
                              <!--                                      @click="-->
                              <!--                                        currentAuthorizations[key].config[setting] = moment(0).add(365, 'days').unix()-->
                              <!--                                      "-->
                              <!--                                    >-->
                              <!--                                      {{ $t('general.durationYear') }}-->
                              <!--                                    </button>-->
                              <!--                                  </div>-->
                              <!--                                  <div class="col-12 mt-h">-->
                              <!--                                    <button-->
                              <!--                                      class="btn btn-success btn-block"-->
                              <!--                                      type="button"-->
                              <!--                                      @click="setChooseIntervalShowingForAuthorizations(key, setting)"-->
                              <!--                                    >-->
                              <!--                                      {{ $t('portalProfiles.customTimeInterval') }}-->
                              <!--                                    </button>-->
                              <!--                                  </div>-->
                              <!--                                </div>-->
                              <!--                              </template>-->
                              <!--                              <template-->
                              <!--                                v-if="-->
                              <!--                                  chooseIntervalShowingForAuthorizations &&-->
                              <!--                                  chooseIntervalShowingForAuthorizations[key] &&-->
                              <!--                                  chooseIntervalShowingForAuthorizations[key][setting] === true-->
                              <!--                                "-->
                              <!--                              >-->
                              <!--                                <label for="amount-time">-->
                              <!--                                  {{ $t('portalProfiles.chooseCustomInterval') }}-->
                              <!--                                  <i-->
                              <!--                                    class="fa fa-remove text-danger ml-q"-->
                              <!--                                    @click="$set(chooseIntervalShowingForAuthorizations[key], setting, false)"-->
                              <!--                                  ></i>-->
                              <!--                                </label>-->
                              <!--                                <div class="col-12">-->
                              <!--                                  <div class="row">-->
                              <!--                                    <input-->
                              <!--                                      v-model="currentAuthorizations[key].customTimeInterval[setting].amount"-->
                              <!--                                      name="amount-time"-->
                              <!--                                      placeholder="Choose amount"-->
                              <!--                                      class="col-4 form-control"-->
                              <!--                                      size="1"-->
                              <!--                                    />-->
                              <!--                                    <select-->
                              <!--                                      v-model="currentAuthorizations[key].customTimeInterval[setting].metrics"-->
                              <!--                                      name="metrics-time"-->
                              <!--                                      class="col-4 form-control"-->
                              <!--                                      placeholder="of units"-->
                              <!--                                    >-->
                              <!--                                      <option value="seconds">{{ $t('drPicker.seconds') }}</option>-->
                              <!--                                      <option value="minutes">{{ $t('drPicker.minutes') }}</option>-->
                              <!--                                      <option value="hours">{{ $t('drPicker.hours') }}</option>-->
                              <!--                                      <option value="days">{{ $t('drPicker.days') }}</option>-->
                              <!--                                      <option value="weeks">{{ $t('drPicker.weeks') }}</option>-->
                              <!--                                      <option value="months">{{ $t('drPicker.months') }}</option>-->
                              <!--                                      <option value="years">{{ $t('drPicker.years') }}</option>-->
                              <!--                                    </select>-->
                              <!--                                    <button-->
                              <!--                                      class="col-4 btn btn-success"-->
                              <!--                                      @click="handleCustomTimeAuthorization(key, setting)"-->
                              <!--                                    >-->
                              <!--                                      {{ $t('drPicker.apply') }}-->
                              <!--                                    </button>-->
                              <!--                                  </div>-->
                              <!--                                </div>-->
                              <!--                              </template>-->
                              <template
                                v-if="
                                  !chooseIntervalShowingForAuthorizations ||
                                  !chooseIntervalShowingForAuthorizations[key] ||
                                  !chooseIntervalShowingForAuthorizations[key][setting] ||
                                  chooseIntervalShowingForAuthorizations[key][setting] === false
                                "
                              >
                                <label class="authZ-settings-fields-height-hack" for="amount-time">
                                  {{ $t('drPicker.chooseInterval') }}
                                </label>
                                <div class="d-flex justify-content-start flex-wrap">
                                  <WButton success
                                    customClass="portal-profiles-interval-select-buttons"
                                    @click="currentAuthorizations[key].config[setting] = moment(0).add(12, 'hours').unix()"
                                  >
                                    {{ $t('general.duration12h') }}
                                  </WButton>
                                  <WButton success
                                    customClass="portal-profiles-interval-select-buttons"
                                    @click="currentAuthorizations[key].config[setting] = moment(0).add(1, 'days').unix()"
                                  >
                                    {{ $t('general.durationDay') }}
                                  </WButton>
                                  <WButton success
                                    customClass="portal-profiles-interval-select-buttons"
                                    @click="currentAuthorizations[key].config[setting] = moment(0).add(31, 'days').unix()"
                                  >
                                    {{ $t('general.durationMonth') }}
                                  </WButton>
                                  <WButton success
                                    customClass="portal-profiles-interval-select-buttons"
                                    @click="currentAuthorizations[key].config[setting] = moment(0).add(365, 'days').unix()"
                                  >
                                    {{ $t('general.durationYear') }}
                                  </WButton>
                                  <WButton success
                                    customClass="portal-profiles-interval-select-buttons"
                                    @click="setChooseIntervalShowingForAuthorizations(key, setting)"
                                  >
                                    {{ $t('portalProfiles.customTimeInterval') }}
                                  </WButton>
                                </div>
                              </template>
                              <template
                                v-if="
                                  chooseIntervalShowingForAuthorizations &&
                                  chooseIntervalShowingForAuthorizations[key] &&
                                  chooseIntervalShowingForAuthorizations[key][setting] === true
                                "
                              >
                                <label class="authZ-settings-fields-height-hack" for="amount-time">
                                  {{ $t('portalProfiles.chooseCustomInterval') }}
                                  <i
                                    role="button"
                                    class="fa fa-remove text-danger ml-q"
                                    @click="$set(chooseIntervalShowingForAuthorizations[key], setting, false)"
                                  ></i>
                                </label>
                                <div class="col-12">
                                  <div class="row">
                                    <input
                                      v-model="currentAuthorizations[key].customTimeInterval[setting].amount"
                                      name="amount-time"
                                      placeholder="Choose amount"
                                      class="col-4 form-control"
                                      size="1"
                                    />
                                    <select
                                      v-model="currentAuthorizations[key].customTimeInterval[setting].metrics"
                                      name="metrics-time"
                                      class="col-4 form-control"
                                      placeholder="of units"
                                    >
                                      <option value="seconds">{{ $t('drPicker.seconds') }}</option>
                                      <option value="minutes">{{ $t('drPicker.minutes') }}</option>
                                      <option value="hours">{{ $t('drPicker.hours') }}</option>
                                      <option value="days">{{ $t('drPicker.days') }}</option>
                                      <option value="weeks">{{ $t('drPicker.weeks') }}</option>
                                      <option value="months">{{ $t('drPicker.months') }}</option>
                                      <option value="years">{{ $t('drPicker.years') }}</option>
                                    </select>
                                    <WButton success
                                      customClass="portal-profiles-interval-select-buttons"
                                      @click="handleCustomTimeAuthorization(key, setting)"
                                    >
                                      {{ $t('drPicker.apply') }}
                                    </WButton>
                                  </div>
                                </div>
                              </template>
                              <span v-show="errors.has(`${key}-${setting}`)" class="help is-danger">
                                {{ errors.first(`${key}-${setting}`) }}
                              </span>
                            </div>
                                                      <div class="col-xs-12 col-6 form-group mt-2"
                               v-if="setting === 'transferable'">
                            <Switch-component
                              v-model="currentAuthorizations[key].transferable"
                              :disabled="isDisable"
                              :label="$t(`${translate}`)"
                              :id="`authz-general-settings-${setting}`"
                            />
                            <info class="ml-h" :content="$t('portal.transferableInfo')"/>
                          </div>
                          </div>

                        </div>
                      </div>
                    </template>
                    <template v-if="key === 'voucher'">
                      <!-- SMS code (voucher code) -->
                      <div class="col-12">
                        <div class="authorization-categories">{{ $t('portal.voucherCodeText') }}:</div>
                        <div class="row">
                          <div class="col-6">
                            <!--<label class = 'authorization-categories' :for="'voucher-code-text'">{{ $t('portal.voucherCodeText') }}</label>-->
                            <input
                              type="text"
                              name="voucher-code-text"
                              id="voucher-code-text"
                              placeholder="Code: {CODE}"
                              :readonly="isDisable"
                              class="form-control input"
                              v-validate="'required|sms_code'"
                              :data-vv-as="$t('portal.voucherCodeText')"
                              size="1"
                              :class="{ 'is-danger': errors.has('voucher-code-text') }"
                              v-model="updatedProfile.push_text.voucher"
                            />
                            <span
                              v-tooltip.top-center="{
                                content: errors.first('voucher-code-text')
                              }"
                              v-show="errors.has('voucher-code-text')"
                              class="help is-danger error_tooltip"
                            >
                              <i class="fa fa-warning text-error"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <!-- Payment system -->
                      <div class="col-12">
                        <div class="authorization-categories">{{ $t('portal.paymentSystems') }}:</div>
                        <div class="row">
                          <div class="col-12">
                            <multiselect
                              :class="{ 'is-danger': errors.has('payment-systems'), 'multiselect-disabled': isDisable }"
                              :custom-label="
                                (option) => getSystemByID(option).identity || $t('general.noDataToDisplay')
                              "
                              :data-vv-name="$t('portal.paymentSystems')"
                              :disabled="isDisable"
                              :hide-selected="true"
                              :multiple="true"
                              :options="paymentSystemsIDs || []"
                              :placeholder="$t('portal.paymentSystems')"
                              class="input"
                              id="payment-systems"
                              v-model="updatedProfile.payment_systems"
                            >
                              <template slot="option" slot-scope="props">
                                <div class="d-flex">
                                  <div
                                    class="payment-logo-preview"
                                    :style="`background-image: url('${getSystemByID(props.option).image}')`"
                                  />

                                  <div class="ml-h">
                                    <span class="d-block mb-h">{{ getSystemByID(props.option).identity }}</span>
                                    <small v-if="getSystemByID(props.option).name">
                                      {{ $t('paymentSystems.name') }}: {{ getSystemByID(props.option).name }}
                                    </small>
                                  </div>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div class="col-12">
                      <div v-if="!socials.includes(key)" class="authorization-categories">
                        {{ $t('portalProfiles.advertismentSettings') }}:
                      </div>
                      <div v-if="!socials.includes(key)" class="form-group">
                        <label :for="`${key}-ads`">{{ $t('adsprofile.ads') }}</label>
                        <multiselect
                          data-vv-name="ads"
                          v-model="currentAuthorizations[key].ads"
                          :options="portalAdsList || []"
                          label="name"
                          :id="`${key}-ads`"
                          track-by="name"
                          :multiple="true"
                          :hide-selected="true"
                          class="input"
                          :class="{ 'is-danger': errors.has('ads'), 'multiselect-disabled': isDisable }"
                          :placeholder="$t('adsprofile.ads')"
                          :disabled="isDisable"
                        >
                          <template slot="option" slot-scope="props">
                            <span class="d-block mb-h">{{ props.option.name }}</span>
                            <small>
                              {{ $t('adsprofile.priority') }}: {{ props.option.priority }},
                              {{ getAdStatus(props.option) }}
                            </small>
                          </template>
                        </multiselect>
                      </div>

                      <div
                        v-if="!socials.includes(key) && currentAuthorizations[key].hasOwnProperty('ads_to_watch')"
                        class="row"
                      >
                        <div class="col-xs-12 col-lg-4">
                          <label for="ads_to_watch">{{ $t('portal.ads_to_watch') }}</label>
                          <input
                            :class="{ 'is-danger': errors.has(`${key}-ads_to_watch`) }"
                            :name="`${key}-ads_to_watch`"
                            :placeholder="$t('portal.ads_to_watch')"
                            :readonly="isDisable"
                            class="form-control input"
                            :data-vv-as="$t('portal.ads_to_watch')"
                            id="ads_to_watch"
                            type="number"
                            v-model.number="currentAuthorizations[key].ads_to_watch"
                            v-validate="`required|between:0,${currentAuthorizations[key].ads.length}`"
                          />
                          <span v-show="errors.has(`${key}-ads_to_watch`)" class="help is-danger">
                            {{ errors.first(`${key}-ads_to_watch`) }}
                          </span>
                        </div>
                        <div class="col-xs-12 col-lg-8 form-group mb-0">
                          <div class="mt-1">
                            <Switch-component
                              v-model="currentAuthorizations[key].enable_rotation"
                              :disabled="isDisable"
                              :label="$t('portal.adsRotation')"
                              :id="`ads-rotation-${key}`"
                            />
                          </div>
                          <div class="mt-1">
                            <Switch-component
                              v-model="currentAuthorizations[key].enable_ad_follow"
                              :disabled="isDisable"
                              :label="$t('portal.adsFollow')"
                              :id="`ads-follow-${key}`"
                            />
                          </div>
                          <div class="mt-1">
                            <Switch-component
                              v-model="currentAuthorizations[key].skip_final_page"
                              :disabled="isDisable"
                              :label="$t('portal.skipFinalPage')"
                              :id="`skip-final-page-${key}`"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="d-flex flex-column" v-if="key === 'vk' || key === 'facebook'">
                        <div class="btn-group float-left" data-toggle="button">
                          <label class="btn btn-outline-primary" :class="{ active: socialAuth[key].type === 'auth' }">
                            <input type="radio" name="socialAuth" @click="setSocialAuth(key, 'auth')" />
                            {{ $t('portal.authOnly') }}
                          </label>
                          <label
                            class="btn btn-outline-primary"
                            :class="{ active: socialAuth[key].type === 'auth_post' }"
                          >
                            <input type="radio" name="socialAuth" @click="setSocialAuth(key, 'auth_post')" />
                            {{ $t('portal.authPost') }}
                          </label>
                          <label
                            class="btn btn-outline-primary"
                            :class="{ active: socialAuth[key].type === 'auth_group' }"
                          >
                            <input type="radio" name="socialAuth" @click="setSocialAuth(key, 'auth_group')" />
                            {{ $t('portal.authGroup') }}
                          </label>
                        </div>

                        <div v-if="socialAuth[key].type === 'auth'" class="form-group mt-h">
                          <label :for="`${key}-social-redirect_url`" class="required-mark">
                            {{ $t('portal.auth.redirectURL') }}
                          </label>
                          <input
                            :class="{ 'is-danger': errors.has(`${key}-social-redirect_url`) }"
                            :data-vv-as="`${$t('portal.auth.redirectURL')}`"
                            :id="`${key}-social-redirect_url`"
                            :name="`${key}-social-redirect_url`"
                            :readonly="isDisable"
                            class="form-control input"
                            placeholder="Redirect URL"
                            type="text"
                            v-model="currentAuthorizations[key].social_network_config.redirect_url"
                            v-validate="{ required: true, min: 3 }"
                          />
                          <span v-show="errors.has(`${key}-social-redirect_url`)" class="help is-danger">
                            {{ errors.first(`${key}-social-redirect_url`) }}
                          </span>
                        </div>

                        <div v-if="socialAuth[key].type === 'auth_post'">
                          <div class="form-group mt-h">
                            <label :for="`${key}-social-post-message`" class="required-mark">
                              {{ $t('portal.auth.message') }}
                            </label>
                            <textarea
                              :class="{ 'is-danger': errors.has(`${key}-social-post-message`) }"
                              :data-vv-as="$t('portal.auth.message')"
                              :id="`${key}-social-post-message`"
                              :name="`${key}-social-post-message`"
                              :readonly="isDisable"
                              class="form-control input"
                              placeholder="Post message"
                              rows="2"
                              type="text"
                              v-model="currentAuthorizations[key].social_network_config.post.message"
                              v-validate="{ required: true, min: 3 }"
                            />
                            <span v-show="errors.has(`${key}-social-post-message`)" class="help is-danger">
                              {{ errors.first(`${key}-social-post-message`) }}
                            </span>
                          </div>

                          <div class="form-group mt-h">
                            <label :for="`${key}-social-post-url`" class="required-mark">
                              {{ $t('portal.auth.attachedURL') }}
                            </label>
                            <input
                              :class="{ 'is-danger': errors.has(`${key}-social-post-url`) }"
                              :data-vv-as="$t('portal.auth.attachedURL')"
                              :id="`${key}-social-post-url`"
                              :name="`${key}-social-post-url`"
                              :readonly="isDisable"
                              class="form-control input"
                              placeholder="Post URL"
                              rows="2"
                              type="text"
                              v-model="currentAuthorizations[key].social_network_config.post.attached_url"
                              v-validate="{ required: true, min: 3 }"
                            />
                            <span v-show="errors.has(`${key}-social-post-url`)" class="help is-danger">
                              {{ errors.first(`${key}-social-post-url`) }}
                            </span>
                          </div>
                        </div>

                        <div v-if="socialAuth[key].type === 'auth_group'" class="form-group mt-h">
                          <label :for="`${key}-social-group_url`" class="required-mark">
                            {{ $t('portal.auth.groupURL') }}
                          </label>
                          <input
                            :class="{ 'is-danger': errors.has(`${key}-social-group_url`) }"
                            :data-vv-as="$t('portal.auth.groupURL')"
                            :id="`${key}-social-group_url`"
                            :name="`${key}-social-group_url`"
                            :readonly="isDisable"
                            class="form-control input"
                            placeholder="Group URL"
                            type="text"
                            v-model="currentAuthorizations[key].social_network_config.group_url"
                            v-validate="{ required: true, socialAuthURL: true, min: 3 }"
                          />
                          <span v-show="errors.has(`${key}-social-group_url`)" class="help is-danger">
                            {{ errors.first(`${key}-social-group_url`) }}
                          </span>
                        </div>
                      </div>

                      <div v-if="!socials.includes(key)" class="form-group mt-h">
                        <label :for="`${key}-redirect_url`">{{ $t('portal.redirect') }}</label>
                        <input
                          :class="{ 'is-danger': errors.has(`${key}-redirect_url`) }"
                          :data-vv-as="$t('portal.redirect')"
                          :id="`${key}-redirect_url`"
                          :name="`${key}-redirect_url`"
                          :readonly="isDisable"
                          class="form-control input"
                          placeholder="Redirect URL"
                          type="text"
                          v-model="currentAuthorizations[key].redirect_url"
                          v-validate="'required'"
                        />
                        <span v-show="errors.has(`${key}-redirect_url`)" class="help is-danger">
                          {{ errors.first(`${key}-redirect_url`) }}
                        </span>
                      </div>

                      <div v-if="key === 'voucher'" class="form-group mt-h">
                        <div class="authorization-categories">{{ $t('portalProfiles.vouchersSettings') }}:</div>
                        <div class="form-group mt-h">
                          <label :for="`${key}-header-text`">{{ $t('portal.voucherBuyHeader') }}</label>
                          <textarea
                            :class="{ 'is-danger': errors.has(`${key}-header-text`) }"
                            :data-vv-as="$t('portal.voucherBuyHeader')"
                            :id="`${key}-header-text`"
                            :name="`${key}-header-text`"
                            :readonly="isDisable"
                            class="form-control input"
                            placeholder="Header"
                            rows="2"
                            type="text"
                            v-model="currentAuthorizations[key].header"
                            v-validate="'required'"
                          />
                          <span v-show="errors.has(`${key}-header-text`)" class="help is-danger">
                            {{ errors.first(`${key}-header-text`) }}
                          </span>
                        </div>
                        <div class="form-group mt-h">
                          <label :for="`${key}-header-info`">{{ $t('portal.voucherBuyInfo') }}</label>
                          <textarea
                            :class="{ 'is-danger': errors.has(`${key}-header-info`) }"
                            :data-vv-as="$t('portal.voucherBuyInfo')"
                            :id="`${key}-header-info`"
                            :name="`${key}-header-info`"
                            :readonly="isDisable"
                            class="form-control input"
                            placeholder="Information"
                            rows="4"
                            type="text"
                            v-model="currentAuthorizations[key].info"
                            v-validate="'required'"
                          />
                          <span v-show="errors.has(`${key}-header-info`)" class="help is-danger">
                            {{ errors.first(`${key}-header-info`) }}
                          </span>
                        </div>
                        <div class="form-group mt-1 mb-q">
                          <Switch-component
                            v-model="currentAuthorizations[key].notification.enable"
                            :disabled="isDisable"
                            :label="$t('portal.notification')"
                            :id="`notification-${key}`"
                          />
                        </div>
                        <template v-if="currentAuthorizations[key].notification.enable">
                          <div class="form-group mt-h ml-1">
                            <label :for="`${key}-notification-text`">{{ $t('portal.notificationText') }}</label>
                            <input
                              :class="{ 'is-danger': errors.has(`${key}-notification-text`) }"
                              :data-vv-as="$t('portal.notificationText')"
                              :id="`${key}-notification-text`"
                              :name="`${key}-notification-text`"
                              :readonly="isDisable"
                              class="form-control input"
                              placeholder="SMS text"
                              size="2"
                              type="text"
                              v-model="currentAuthorizations[key].notification.text"
                              v-validate="'required'"
                            />
                            <span v-show="errors.has(`${key}-notification-text`)" class="help is-danger">
                              {{ errors.first(`${key}-notification-text`) }}
                            </span>
                          </div>
                          <div class="form-group mt-h ml-1">
                            <label :for="`${key}-notification-time`">{{ $t('portal.notificationTime') }}</label>
                            <input
                              :data-vv-as="$t('portal.notificationTime')"
                              :class="{ 'is-danger': errors.has(`${key}-notification-time`) }"
                              :id="`${key}-notification-time`"
                              :name="`${key}-notification-time`"
                              :readonly="isDisable"
                              class="form-control input"
                              placeholder="10"
                              size="1"
                              type="number"
                              v-model.number="currentAuthorizations[key].notification.seconds_before_stop"
                            />
                            <span v-show="errors.has(`${key}-notification-time`)" class="help is-danger">
                              {{ errors.first(`${key}-notification-time`) }}
                            </span>
                          </div>
                        </template>
                      </div>
                      <div class="row mb-1">
                        <!--                        hide button-->
                        <!--                        <div class="col-4">-->
                        <!--                          <button class="btn btn-success btn-block" type="button" @click="closeAuthorizationItem(key)">-->
                        <!--                            <i class="fa fa-long-arrow-up"></i>-->
                        <!--                            {{ $t('portalProfiles.cutDownButton') }}-->
                        <!--                            <i class="fa fa-long-arrow-up"></i>-->
                        <!--                          </button>-->
                        <!--                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
<!--                <div v-if="currentAuthorizations[key] && key === 'subscription'">-->
<!--                  <div v-show="openedAuthorizations[key]">-->


<!--                    <div class="row" v-for="(translate, setting) in filteredSettings"-->
<!--                         :key="setting">-->
<!--                      <div v-if="setting === 'transferable'" class="col-xs-12 col-6 form-group">-->
<!--                        <div class="ml-2"-->
<!--                             v-if="setting === 'transferable'">-->
<!--                          <Switch-component-->
<!--                            v-model="currentAuthorizations[key].transferable"-->
<!--                            :disabled="isDisable"-->
<!--                            :label="$t(`${translate}`)"-->
<!--                            :id="`authz-general-settings-${setting}`"-->
<!--                          />-->
<!--                          <info class="ml-h" :content="$t('portal.transferableInfo')"/>-->
<!--                        </div>-->

<!--                      </div>-->
<!--                    </div>-->


<!--                  </div>-->

<!--                </div>-->

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="modal-footer justify-content-between">
        <WButton success outline
          v-if="!isNewProfile && !isDisable"
          @click="updateProfile(updatedProfile)"
          :disabled="errors.any() || isValidationErrorsInPortalProfileGateWaySettingsComponents || muteEdit || hasCheckAuthNsWhenAuthenSkipIsFalseError"
        >
          <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
          <span v-if="muteEdit" class="loader loader--mini"></span>
        </WButton>
        <WButton success outline
          v-else-if="!isDisable"
          @click="createProfile(updatedProfile)"
          :disabled="muteEdit || errors.any() || isValidationErrorsInPortalProfileGateWaySettingsComponents || hasCheckAuthNsWhenAuthenSkipIsFalseError"
        >
          <span :class="{ invisible: muteEdit }">{{ $t('general.create') }}</span>
          <span v-if="muteEdit" class="loader loader--mini"></span>
        </WButton>
        <div class="validatorErrors" v-if="validatorErrors || isValidationErrorsInPortalProfileGateWaySettingsComponents">
          {{ $t('portalProfiles.validatorErrors') }}
        </div>
        <div>
          <WButton danger outline
            customClass="mr-2"
            v-if="!isNewProfile && !isDisable"
            @click="isDeleteOpen = true"
          >
            {{ $t('general.delete') }}
          </WButton>
          <WButton secondary outline
            @click="cancelEditMode"
          >
            {{ $t('general.close') }}
          </WButton>
        </div>
      </div>
    </Modal>

    <Delete-dialog
      :is-open="isDeleteOpen"
      :handle-close="handleDeleteDialogClose.bind(this)"
      :handle-delete="deleteProfile.bind(this)"
      :target-text="updatedProfile.name"
    />

    <Close-dialog
      :is-open="isCloseModalOpen"
      :handle-yes="handleConfirmClose.bind(this)"
      :handle-no="closeConfirmModal.bind(this)"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { Multiselect } from 'vue-multiselect';
import { API_URL, LK_URL } from '@/config';
import { portalAuthorizationsMixin } from '@/mixins';
import MultiSelectComponent from '../../components/Universal/select/multi-select-component.vue';
import commonService from '../../services/commonService';
import cpeService from '../../services/cpeService';
import wlanService from '../../services/wlanService';
import portalProfilesService from '../../services/portalProfilesService';
import portalAdsService from '../../services/portalAdsService';
import getAdStatus from '../../helpers/getAdStatus';
import Modal from '../../components/Modal.vue';
import EmptyCellValue from '../../components/empty-cell-value.vue';
import phoneCodes from './codes.json';
import AddList from '../../components/UI/AddList.vue';
import TablePaginationV3 from '../../components/table-pagination-v3.vue';
import DeleteDialog from '../../components/delete-dialog.vue';
import CloseDialog from '../../components/close-dialog.vue';
import helpers from '../../helpers';
import locationService from '../../services/locationService';
import portalPaymentSystemsService from '../../services/portalPaymentSystemsService';
// import timeZones from './timeZones.json';
import timeZones from '@/timeZones';
import DataIteratorCard from '../../components/Universal/data-iterator-card.vue';
import Info from '../../components/Universal/info-icon.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import VueNotifications from "vue-notifications";
import PortalProfileGatewaySettings from '../../components/Portal/PortalProfileGatewaySettings.vue';

const watcher = new helpers.ChangesWatcher();

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');
const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');

export default {
  name: 'PortalProfilesNew',
  mixins: [portalAuthorizationsMixin],
  components: {
    Modal,
    Multiselect,
    MultiSelectComponent,
    AddList,
    TablePaginationV3,
    DeleteDialog,
    CloseDialog,
    EmptyCellValue,
    DataIteratorCard,
    SwitchComponent,
    Info,
    PortalProfileGatewaySettings
  },
  data() {
    return {
      hasValidationErrorsInPortalProfileGateWaySettingsComponents: {
        // если в компонентах portalProfileGatewaySettings появляется ошибка валидатора
        // то тут создается записиь вида имя: true
        // а computed isValidationErrorsInPortalProfileGateWaySettingsComponents следит за этим
        // и с его помощью блокируются кнопки Сохранить и Создать в модалках редактировния профилей
      },
      showTablePaginationV3Component: true,
      charactersForCpeListSeparateNameFromDescription: ':::', // используем при создании списка CPE с описаниями  для мультиселекта
      showDescriptionInCpesFilter: false,
      conditionLocIdShowingBlock: true,
      chooseIntervalShowingForAuthorizationsGeneral: {},
      generalAuthZSettings: {
        auth_timeout: {
          value: 1800,
          metrics: 'days',
          amount: 1
        },
        block_after: {
          value: 0,
          metrics: 'days',
          amount: 1
        },
        block_expire: {
          value: 0,
          metrics: 'days',
          amount: 1
        },
        block_timeout: {
          value: 0,
          metrics: 'days',
          amount: 1
        },
        download_limit: {
          value: 0,
          metrics: 'days',
          amount: 1
        },
        max_sessions: {
          value: 0,
          metrics: 'days',
          amount: 1
        },
        timeout: {
          value: 1800,
          metrics: 'days',
          amount: 1
        },
        transferable: {
          value: false
        }
      },
      isGeneralAuthZSettingsBlockOpened: false,
      chooseIntervalShowingForAuthenticationGeneral: false,
      generalAuthNSettings: {
        remember: 86400,
        metrics: 'days',
        amount: 1
      },
      isGeneralAuthNSettingsBlockOpened: false,
      checkAuthNsWhenAuthenSkipIsFalseError: '',
      isAllAuhNsSwitchersLocked: false,
      isSelectorForAuthZSameSettingsShown: true,
      isSelectorForAuthNSameSettingsShown: true,
      validatorErrors: false,
      // updatedProfileBaseLocation: '',
      // newProfileBaseLocation: this.$store.state.userData.base_location,
      // dataIteratorView отвечает за включение режима отображения в виде итератора, если поставить
      // в false то покажет старую версию в виде таблицы
      dataIteratorView: true,
      chooseIntervalShowingForAuthentication: {},
      chooseIntervalShowingForAuthorizations: {},
      offset: 0,
      canLoadMorePortalProfiles: true,
      totalResultPortalProfiles: false,
      tosMuteUpload: false,
      isNewProfile: false,
      isDeleteOpen: false,
      isCloseModalOpen: false,
      hasAgreement: false,
      socialAuth: {
        vk: { type: 'auth' },
        facebook: { type: 'auth' }
      },
      TOS: {
        text: {
          ru: '',
          en: '',
          tg: ''
        },
        currentLang: 'ru'
      },
      newProfile: {
        authn_skip: false,
        authz_default: '',
        base_location: this.$store.state.userData.base_location,
        name: '',
        description: '',
        access_list: {},
        black_list: {},
        white_list: {},
        push_text: { otp: '', voucher: '' },
        terms_of_service: { enable: false },
        authentications: [],
        authorizations: [],
        condition: {
          cpe: [],
          wlan: [],
          nas_id: [],
          loc_id: []
        },
        msisdn_config: {
          prefix: {}
        },
        updatedProfile: [],
        random_mac_block_page: false
      },
      isProfileModalOpen: false,
      authorizationSettings: {
        timeout: 'portal.timeout',
        auth_timeout: 'portal.auth_timeout',
        block_after: 'portal.block_after',
        block_expire: 'portal.block_expire',
        download_limit: 'portal.download_limit',
        max_sessions: 'portal.max_sessions',
        transferable: 'portal.transferable',
      },
      updatedProfile: false,
      conditionWlans: [],
      conditionCpes: [],
      conditionNasId: [],
      conditionLocId: [],
      newPhoneCode: '',
      newPrefix: '',
      currentAuthentications: {},
      currentAuthorizations: {},
      openedAuthorizations: {},
      openedAuthentications: {},
      isWlanDescription: false,
      filters: {
        limit: 30,
        offset: 0,
        sort_by: 'name',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        with_childs: false,
        location: {
          data: {},
          with_childs: false,
        }
      },
      socials: ['facebook', 'vk', 'instagram'],
      maskTokens: {
        p: { pattern: /-?/ },
        '#': { pattern: /\d/ }
      },
      selectedTimeZone: false
    };
  },
  computed: {
    ...brandingMapGetters(['isBeelineBranding']),
    ...featureFlagsMapGetters(['isPortalOnlyModeEnabled', 'isContentAuthFreemiumEnabled']),
    isValidationErrorsInPortalProfileGateWaySettingsComponents() {
      if (Object.keys(this.hasValidationErrorsInPortalProfileGateWaySettingsComponents).length) {
        return true;
      }
      return false;
    },
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    lkUrl() {
      // URL личного кабинет пользователя
      const domain = this.LK_URL;
      const shortId = this.updatedProfile.short_id;

      return `${domain}/${shortId}`;
    },
    isFiltersActive(){
      return this.filters.limit !== 30 || this.filters.sort_by !== 'name' ||
        this.filters.sort_order !== 1 || this.filters.search !== '' ||
        (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'data') &&
        typeof this.filters.location.data  === 'object' && Object.keys(this.filters.location.data).length !== 0 )
    },
    selectedconditionCpesCount() {
      try {
        if (Array.isArray(this.conditionCpes)) {
          return this.conditionCpes.length;
        }
        return 0;
      } catch (e) {
        return 0;
      }
    },
    selectedconditionWlansCount() {
      try {
        if (Array.isArray(this.conditionWlans)) {
          return this.conditionWlans.length;
        }
        return 0;
      } catch (e) {
        return 0;
      }
    },
    hasCheckAuthNsWhenAuthenSkipIsFalseError() {
      if (this.checkAuthNsWhenAuthenSkipIsFalseError !== '' && !this.checkAuthNsWhenAuthenSkipIsFalse()) {
        return true;
      }
      return false;
    },
    isSelectedAuthNsWhenAuthenSkipIsFalse() {
      // проверяем выбрана ли хотя бы одна аутентификация если выключен свитчер authn_skip
      // другими словами выбрано ли достаточно аутентификаций:
      // при authn_skip === false должно быть выключено больше нуля
      // при authn_skip === true неважно? может быть и ноль
      // если условия удовлетворяются возвращаем true, иначе false
      try {
        if (this.updatedProfile.authn_skip !== true) {
          if (Object.entries(this.currentAuthentications).length > 0) {
            return true;
          }
          return false;
        }
        return true;
      } catch (e) {
        return true;
      }
    },
    updatedProfileAccessListLength() {
      if (
        this.updatedProfile &&
        this.updatedProfile.access_list &&
        Object.keys(this.updatedProfile.access_list).length
      ) {
        return Object.keys(this.updatedProfile.access_list).length;
      }
      return 0;
    },
    updatedProfileAccessListAsIdsArray() {
      if (
        this.updatedProfile &&
        this.updatedProfile.access_list &&
        Object.keys(this.updatedProfile.access_list).length
      ) {
        return Object.keys(this.updatedProfile.access_list);
      }
      return [];
    },
    isCurrentUserMarketer() {
      return this.$store.state.userData.role === 'marketer';
    },
    disableCpeWlanInInterface() {
      if (this.isPortalOnlyModeEnabled) {
        return true;
      }
      return false;
    },
    isauthNEnabled() {
      if (this.updatedProfile && Object.entries(this.currentAuthentications).length > 0) {
        return true;
      }
      return false;
    },
    isAuthNNeedingOTPCodeEnabled() {
      if (
        this.isauthNEnabled &&
        (Object.keys(this.currentAuthentications).includes('sms') ||
          Object.keys(this.currentAuthentications).includes('email'))
      ) {
        return true;
      }
      return false;
    },
    isPortalProfilesLocationsLoading() {
      return this.$store.state.loadingPortalProfilesLocations;
    },
    locationsListByProfileId() {
      return this.$store.state.portalProfilesLocationsList;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    muteEdit() {
      return this.$store.state.mutePortalProfileEdit;
    },
    portalProfilesList() {
      return this.$store.state.portalProfilesList;
    },
    portalAdsList() {
      return this.$store.state.portalAdsList;
    },
    cpesList() {
      return this.$store.state.cpesList;
    },
    cpesListForCPEMultiselect() {
      const cpesList = [];
      const cpes = JSON.parse(JSON.stringify(this.cpesList));
      for (const cpeIndex in cpes) {
        if (this.showDescriptionInCpesFilter === true) {
          if (cpes[cpeIndex].description !== '') {
            cpes[
              cpeIndex
            ].name = `${cpes[cpeIndex].name}${this.charactersForCpeListSeparateNameFromDescription} ${cpes[cpeIndex].description}`;
            cpesList.push(cpes[cpeIndex]);
          } else {
            cpes[cpeIndex].name = `${cpes[cpeIndex].name}`;
            cpesList.push(cpes[cpeIndex]);
          }
        } else {
          cpesList.push(cpes[cpeIndex]);
        }
      }
      return cpesList;
    },
    locationsForLocIdCondition() {
      const result = [];
      let baseLocation = {};
      const userBaseLocation = JSON.parse(localStorage.getItem('userData')).base_location;
      const locations = JSON.parse(JSON.stringify(this.$store.state.locationsList));
      // console.log(userBaseLocation)
      locations.forEach((location) => {
        if (location.id === userBaseLocation) {
          // this.filters.location = { name: location.name, id: location.id };
          baseLocation = { name: location.name, id: location.id, loc_id: location.loc_id };
          // console.log(baseLocation)
          return;
        }
        result.push({ name: location.name, id: location.id, loc_id: location.loc_id });
      });
      result.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      result.unshift(baseLocation);
      // console.log(result)
      return result;
    },
    wlansList() {
      return this.$store.state.wlansList;
    },
    portalAuthenticationsList() {
      return helpers.transformArrayToObjectByKey(this.$store.state.portalAuthenticationsList, 'type');
    },
    paymentSystemsList() {
      return this.$store.state.portalPaymentSystemsList;
    },
    paymentSystemsIDs() {
      return this.paymentSystemsList.map((it) => (it.id ? it.id : ''));
    },
    phoneCodes() {
      return phoneCodes;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    isPortalProfilesLoading() {
      return this.$store.state.loadingPortalProfiles;
    },
    isCpeLoading() {
      return this.$store.state.loadingCpes;
    },
    isWlansLoading() {
      return this.$store.state.loadingWlans;
    },
    isPortalAdsLoading() {
      return this.$store.state.loadingPortalAds;
    },
    isPortalPaymentSystemsLoading() {
      return this.$store.state.loadingPortalPaymentSystems;
    },
    isAuthenticationsLoading() {
      return this.$store.state.loadingAuthentications;
    },
    isAuthorizationsLoading() {
      return this.$store.state.loadingAuthorizations;
    },
    filteredSettings() {
      const settings = { ...this.authorizationSettings };
      delete settings.download_limit;
      delete settings.auth_timeout;
      return settings;
    },
    previewTOS() {
      if (this.updatedProfile.terms_of_service && this.updatedProfile.terms_of_service.file_url) {
        return `/api/portal-preview${this.updatedProfile.terms_of_service.file_url}`;
      }
      return false;
    },
    timeZonesOptions() {
      return timeZones;
    }
  },
  watch: {
    updatedProfile: {
      deep: true,
      handler() {
        watcher.tick();
      }
    },
    TOS: {
      deep: true,
      handler() {
        const tosToSend = { ...this.TOS.text };
        this.updatedProfile.terms_of_service.text = JSON.stringify(tosToSend);
      }
    }
  },
  methods: {
    hasNoErrorsProfileGatewaySettingsHandler(authNTypeName) {
      // console.log('here no errors')
      this.$delete(this.hasValidationErrorsInPortalProfileGateWaySettingsComponents, authNTypeName)
    },
    hasErrorsProfileGatewaySettingsHandler(authNTypeName) {
      // console.log('here errors')
      this.$set(this.hasValidationErrorsInPortalProfileGateWaySettingsComponents, authNTypeName, true)
    },
    copyToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        () => {
          this.copyToClipboardSuccessShowMsg();
        },
        (err) => {
          this.copyToClipboardErrorShowMsg();
        }
      );
    },
    redrawTablePaginationV3Component(){
      this.showTablePaginationV3Component = false;
      this.$nextTick(()=>{
        this.showTablePaginationV3Component = true;
      })
    },
    // resetAllFilters() {
    //   this.filters = {
    //     limit: 30,
    //     offset: 0,
    //     sort_by: 'name',
    //     sort_order: 1 /* 1 for ascending, -1 for descending */,
    //     search: '',
    //     with_childs: false,
    //     location: {
    //       data: {},
    //       with_childs: false,
    //     }
    //   };
    //   this.redrawTablePaginationV3Component();
    //   this.getWithQuery();
    // },
    resetAllFilters() {
      this.filters = {
        limit: 30,
        offset: 0,
        sort_by: 'name',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
       //  with_childs: false,
        // location: {
        // data: {},
        // with_childs: false,
        // }
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.location.data = {};
        this.filters.location.with_childs = false;
        this.filters.with_childs = false;
      } else {
        this.filters.location = {};
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.location.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }


      this.redrawTablePaginationV3Component();
      this.getWithQuery();
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        this.copyToClipboardSuccessShowMsg();
      } catch (err) {
        this.copyToClipboardErrorShowMsg();
      }

      document.body.removeChild(textArea);
    },
    copyToClipboardErrorShowMsg() {
      VueNotifications.error({message: `${this.$t('aps.cpeConfigShowCopyToClipboardError')}`});
    },
    copyToClipboardSuccessShowMsg() {
      VueNotifications.success({message: `${this.$t('aps.cpeConfigShowCopyToClipboardSuccess')}`});
    },
    changeCPEsInMultiselectInModal() {
      try {
        if (Array.isArray(this.conditionCpes)) {
          for (const cpe of this.conditionCpes) {
            if (cpe.name.includes(this.charactersForCpeListSeparateNameFromDescription)) {
              cpe.name = cpe.name.split(this.charactersForCpeListSeparateNameFromDescription)[0];
            }
          }
        }
      } catch (e) {}
    },
    cutOffLongTextForCPEDescription(text) {
      try {
        if (text.length > 60) {
          return `${text.slice(0, 60)}...`;
        }
        return text;
      } catch (e) {
        return text;
      }
    },
    toggleShowingDescriptionInCpesFilter() {
      this.showDescriptionInCpesFilter = !this.showDescriptionInCpesFilter;
    },
    checkIsFieldInDOM(fieldName) {
      if (document.getElementById(fieldName) !== null) {
        return true;
      }
      return false;
    },
    // applyGeneralAuthZSettings() {
    //   this.$validator.validateAll().then(
    //     (result) => {
    //       if (result) {
    //         // собираем все данные из блока  Общие настройки авторизаций (настройки сессии)  в один объект
    //         const generalAuthZSettings = {}
    //         for (const settingName in this.generalAuthZSettings){
    //           generalAuthZSettings[settingName] = this.generalAuthZSettings[settingName].value;
    //         }
    //         // console.log(generalAuthZSettings)
    //         // console.log(this.currentAuthorizations)
    //
    //         // итерируемся по примененным авторизациям, смотрим их настройки и заменяем их на настройки из
    //         // блока  Общие настройки авторизаций (настройки сессии)
    //         for (const authZName in this.currentAuthorizations) {
    //           // console.log(this.currentAuthorizations[authZName])
    //           if (Object.prototype.hasOwnProperty.call(this.currentAuthorizations[authZName], 'config')){
    //             for(const configName in this.currentAuthorizations[authZName].config){
    //               if (Object.prototype.hasOwnProperty.call(generalAuthZSettings, configName)){
    //                 this.currentAuthorizations[authZName].config[configName] = generalAuthZSettings[configName];
    //               }
    //             }
    //           }
    //         }
    //         // const rememberTimeFromGeneralAuthNSettings = this.generalAuthNSettings.remember;
    //         // for (const authN in this.currentAuthentications) {
    //         //   this.$set(this.currentAuthentications[authN], 'remember', rememberTimeFromGeneralAuthNSettings);
    //         // }
    //       } else {
    //         // console.log('not valid')
    //         // this.validatorErrors = true;
    //       }
    //     },
    //     () => {
    //       console.warn('Validation failed');
    //     }
    //   );
    // },
    applyGeneralAuthZSettings() {
      // проверяем только нужные  поля, чтобы елси есть ошибка в каком нибудь поле за пределами общего блока настроек
      // авторизаций, типа ОТР код (которые не важны для это валидации) тут валидация проходила успешно, и общие настройки применялись
      // тут возможные нзвания полей
      const fieldsForValidateNames = [
        'authz-general-settings-auth_timeout',
        'authz-general-settings-block_after',
        'authz-general-settings-block_expire',
        'authz-general-settings-block_timeout',
        'authz-general-settings-download_limit',
        'authz-general-settings-max_sessions',
        'authz-general-settings-timeout'
      ];
      const validators = [];
      // проверяем отрисовано ли поле в документе и добавляем  валидатор для него в массив с
      // валидаторами
      for (const fieldName of fieldsForValidateNames) {
        if (this.checkIsFieldInDOM(fieldName)) {
          validators.push(this.$validator.validate(fieldName));
        }
      }

      const validatorsResults = Promise.all(validators);
      validatorsResults.then(
        (results) => {
          // console.log(result)
          const isAllValidationResultsIsOk = results.every((result) => {
            if (result === true) {
              return true;
            }
            return false;
          });
          // console.log(isAllValidationResultsIsOk)
          if (isAllValidationResultsIsOk) {
            // если все валидации прошли успешно (нет ни одной ошибки)
            // собираем все данные из блока  Общие настройки авторизаций (настройки сессии)  в один объект
            const generalAuthZSettings = {};
            for (const settingName in this.generalAuthZSettings) {
              generalAuthZSettings[settingName] = this.generalAuthZSettings[settingName].value;
            }
            // console.log(generalAuthZSettings)
            // console.log(this.currentAuthorizations)

            // // итерируемся по примененным авторизациям, смотрим их настройки и заменяем их на настройки из
            // // блока  Общие настройки авторизаций (настройки сессии)
            // for (const authZName in this.currentAuthorizations) {
            //   // console.log(this.currentAuthorizations[authZName])
            //   if (Object.prototype.hasOwnProperty.call(this.currentAuthorizations[authZName], 'config')) {
            //     for (const configName in this.currentAuthorizations[authZName].config) {
            //       if (Object.prototype.hasOwnProperty.call(generalAuthZSettings, configName)) {
            //         this.currentAuthorizations[authZName].config[configName] = generalAuthZSettings[configName];
            //       }
            //     }
            //   }
            // }

            // // итерируемся по примененным авторизациям, смотрим их настройки и заменяем их на настройки из
            // // блока  Общие настройки авторизаций (настройки сессии)
            // for (const authZName in this.currentAuthorizations) {
            //   // console.log(this.currentAuthorizations[authZName])
            //   if (Object.prototype.hasOwnProperty.call(this.currentAuthorizations[authZName], 'config')) {
            //     for (const configName in this.currentAuthorizations[authZName].config) {
            //       if (authZName === 'subscription') {
            //         this.currentAuthorizations.subscription.config.transferable = generalAuthZSettings.transferable;
            //       } else {
            //         if (Object.prototype.hasOwnProperty.call(generalAuthZSettings, configName)) {
            //           this.currentAuthorizations[authZName].config[configName] = generalAuthZSettings[configName];
            //         }
            //       }
            //
            //     }
            //   }
            // }

            // итерируемся по примененным авторизациям, смотрим их настройки и заменяем их на настройки из
            // блока  Общие настройки авторизаций (настройки сессии)
            for (const authZName in this.currentAuthorizations) {
              this.$set(this.currentAuthorizations[authZName], 'transferable', generalAuthZSettings['transferable'] )
              // console.log(this.currentAuthorizations[authZName])
              if (Object.prototype.hasOwnProperty.call(this.currentAuthorizations[authZName], 'config')) {
                for (const configName in this.currentAuthorizations[authZName].config) {
                  if (Object.prototype.hasOwnProperty.call(generalAuthZSettings, configName)) {
                    this.currentAuthorizations[authZName].config[configName] = generalAuthZSettings[configName];
                  }
                }
              }
            }


            this.validatorErrors = false;
            this.errors.clear();
            // const rememberTimeFromGeneralAuthNSettings = this.generalAuthNSettings.remember;
            // for (const authN in this.currentAuthentications) {
            //   this.$set(this.currentAuthentications[authN], 'remember', rememberTimeFromGeneralAuthNSettings);
            // }
          } else {
            // console.log('not valid')
            // this.validatorErrors = true;
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    applyGeneralAuthNSettings() {
      const fieldForValidateName = 'generalAuthNSettings-remember';
      this.$validator.validate(fieldForValidateName).then(
        (result) => {
          if (result) {
            const rememberTimeFromGeneralAuthNSettings = this.generalAuthNSettings.remember;
            for (const authN in this.currentAuthentications) {
              this.$set(this.currentAuthentications[authN], 'remember', rememberTimeFromGeneralAuthNSettings);
            }
          } else {
            // this.validatorErrors = true;
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    resetGeneralAuthNSettingsToDefault() {
      this.chooseIntervalShowingForAuthenticationGeneral = false;
      this.generalAuthNSettings = {
        remember: 86400,
        metrics: 'days',
        amount: 1
      };
    },
    resetGeneralAuthZSettingsToDefault() {
      this.chooseIntervalShowingForAuthorizationsGeneral = {};
      this.generalAuthZSettings = {
        auth_timeout: {
          value: 1800,
          metrics: 'days',
          amount: 1
        },
        block_after: {
          value: 0,
          metrics: 'days',
          amount: 1
        },
        block_expire: {
          value: 0,
          metrics: 'days',
          amount: 1
        },
        block_timeout: {
          value: 0,
          metrics: 'days',
          amount: 1
        },
        download_limit: {
          value: 0,
          metrics: 'days',
          amount: 1
        },
        max_sessions: {
          value: 0,
          metrics: 'days',
          amount: 1
        },
        timeout: {
          value: 1800,
          metrics: 'days',
          amount: 1
        },
        transferable: {
          value: false
        }
      };
    },
    handleCustomTimeAuthenticationForGeneralSettings() {
      const { amount, metrics } = this.generalAuthNSettings;
      this.generalAuthNSettings.remember = this.moment(0).add(amount, metrics).unix();
      this.chooseIntervalShowingForAuthenticationGeneral = false;
    },
    closeGeneralAuthNSettingsBlock() {
      this.isGeneralAuthNSettingsBlockOpened = false;
    },
    toggleGeneralAuthNSettingsBlock() {
      if (this.isAllAuhNsSwitchersLocked === true) {
        return;
      }
      // this.resetGeneralAuthNSettingsToDefault();
      if (this.isGeneralAuthNSettingsBlockOpened === false) {
        this.isGeneralAuthNSettingsBlockOpened = true;
      } else {
        this.isGeneralAuthNSettingsBlockOpened = false;
      }
    },
    closeGeneralAuthZSettingsBlock() {
      this.isGeneralAuthZSettingsBlockOpened = false;
    },
    toggleGeneralAuthZSettingsBlock() {
      // this.resetGeneralAuthNSettingsToDefault();
      if (this.isGeneralAuthZSettingsBlockOpened === false) {
        this.isGeneralAuthZSettingsBlockOpened = true;
      } else {
        this.isGeneralAuthZSettingsBlockOpened = false;
      }
    },
    closeAllAuthNBlocksSettings() {
      // закрываем все равернутые настройки в списке всех аутнтификаций
      for (const openAuthNSettingsName in this.openedAuthentications) {
        this.$set(this.openedAuthentications, openAuthNSettingsName, false);
      }
    },
    checkAuthNsWhenAuthenSkipIsFalseSetError() {
      this.checkAuthNsWhenAuthenSkipIsFalseError = this.$t('portalProfiles.checkAuthNsWhenAuthenSkipIsFalseErrorText');
      this.$nextTick(() => {
        const error = document.getElementById('checkAuthNsWhenAuthenSkipIsFalseError');
        error.scrollIntoView({ block: 'center', behavior: 'smooth' });
      });
    },
    checkAuthNsWhenAuthenSkipIsFalseClearError() {
      this.checkAuthNsWhenAuthenSkipIsFalseError = '';
    },
    checkAuthNsWhenAuthenSkipIsFalse() {
      if (this.isBeelineBranding) {
        // тут проверка на билайн, потому-что сейчас там вобще не показывается блок с вартиантами авторизаций
        // соовтественно если тут не выбрасывать true  и начнется провекрка. Там всегда будет ошибка, поскольку ни
        // один из способов аутентификации не будет выбран (так как их просто нет в интерфейсе и нечего выбирать)
        return true;
      }
      if (this.isSelectedAuthNsWhenAuthenSkipIsFalse === true) {
        return true;
      }
      return false;
    },
    lockAllAuhNsSwitchers() {
      this.isAllAuhNsSwitchersLocked = true;
    },
    unlockAllAuhNSwitchers() {
      this.isAllAuhNsSwitchersLocked = false;
    },
    authNSkipSwitched(switchedValue) {
      // вызывается не только на самом свитчере но и из функций типа sameAsSelectedForAuthN
      // console.log(switcherValue)
      if (switchedValue === true) {
        // блочим все свитчеры
        this.lockAllAuhNsSwitchers();
        // закрываем все раскрытые настройки
        this.closeAllAuthNBlocksSettings();
        this.closeGeneralAuthNSettingsBlock();
      } else {
        this.unlockAllAuhNSwitchers();
      }
    },
    isShowThisAuthorizationType(authorizationType) {
      // для режима AuthFreemium показываем только free-авторизацию
      if (this.isContentAuthFreemiumEnabled) {
        if (authorizationType === 'free') {
          return true;
        }
        return false;
      }
      return true;
    },
    isShowThisAuthenticationType(authenticationType) {
      // для режима AuthFreemium показываем только callfront-аутентификацию
      if (this.isContentAuthFreemiumEnabled) {
        if (authenticationType === 'callfront') {
          return true;
        }
        return false;
      }
      return true;
    },
    goToAccountsWithIdsList(idsList) {
      // console.log(idsList);
      // передаем в UserAccounts список idшников пользователей, а там ловим в хуке created
      this.$router.push({ name: 'UserAccounts', params: { accountsIdsList: idsList } });
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      return locationService.requestLocationsWithSort(searchQuery);
    },
    authNDisable(key) {
      return !this.currentAuthentications[key] && Object.keys(this.currentAuthentications).length >= 5;
    },
    authZDisable(key) {
      return !this.currentAuthorizations[key] && Object.keys(this.currentAuthorizations).length >= 5;
    },
    redrawSelectorsForSameSettings() {
      this.isSelectorForAuthZSameSettingsShown = false;
      this.isSelectorForAuthNSameSettingsShown = false;
      this.$nextTick(() => {
        this.isSelectorForAuthZSameSettingsShown = true;
        this.isSelectorForAuthNSameSettingsShown = true;
      });
    },
    sameAsSelectedForAuthZ(event) {
      const authorizationsDataTargetId = event.target.value;
      // console.log(authorizationsDataTargetId);
      if (authorizationsDataTargetId === 'none') {
        this.$set(this.updatedProfile.push_text, 'voucher', '');
        this.$set(this.updatedProfile, 'payment_systems', []);
        this.$set(this, 'currentAuthorizations', {});
        this.$set(this, 'openedAuthorizations', {});
        this.$set(this.updatedProfile, 'authz_default', '');
      } else {
        const profilesData = JSON.parse(JSON.stringify(this.portalProfilesList));
        // получаем данные по выбранному профилю
        let dataFromTarget = {};
        for (const profile of profilesData) {
          if (profile.id === authorizationsDataTargetId) {
            dataFromTarget = profile;
            break;
          }
        }
        // получаем данные об авторизациях профиля, тексте смс для ваучера и платежных системах для ваучера
        // потом запихиваем это в нужные места
        const authorizationsDataFromTarget = dataFromTarget.authorizations;
        const voucherTextFromTarget = dataFromTarget.push_text.voucher;
        const paymentSystemsFromTarget = dataFromTarget.payment_systems;
        this.$set(this.updatedProfile.push_text, 'voucher', voucherTextFromTarget);
        this.$set(this.updatedProfile, 'payment_systems', paymentSystemsFromTarget);
        // преобразуем данные об авторизациях  в нужный вид
        const currentAuthorizationsDataFromTarget = helpers.transformArrayToObjectByKey(
          authorizationsDataFromTarget,
          'type'
        );
        this.$set(this, 'currentAuthorizations', currentAuthorizationsDataFromTarget);

        // // разворачиваем блоки для скопированных настроек
        // const keysForAuthorizationsTypes = Object.keys(this.currentAuthorizations);
        // keysForAuthorizationsTypes.forEach(item => {
        //   this.$set(this.openedAuthorizations, item, true);
        // });

        // console.log(dataFromTarget)
        // обновляем поле authz_default данными выбранного профиля
        if (Object.prototype.hasOwnProperty.call(dataFromTarget, 'authz_default')) {
          this.$set(this.updatedProfile, 'authz_default', dataFromTarget.authz_default);
        }
      }
    },
    sameAsSelectedForAuthN(event) {
      // получаем целевой id
      const authenticationsDataTargetId = event.target.value;
      if (authenticationsDataTargetId === 'none') {
        this.$set(this.updatedProfile.push_text, 'otp', '');
        this.$set(this, 'currentAuthentications', {});
        this.$set(this, 'openedAuthentications', {});
        this.$set(this.updatedProfile, 'authn_skip', false);
        this.$nextTick(() => {
          this.authNSkipSwitched(this.updatedProfile.authn_skip);
        });
      } else {
        const profilesData = JSON.parse(JSON.stringify(this.portalProfilesList));
        // получаем данные по выбранному профилю
        let dataFromTarget = {};
        for (const profile of profilesData) {
          if (profile.id === authenticationsDataTargetId) {
            dataFromTarget = profile;
            break;
          }
        }
        // получаем данные об аутентификациях профиля и тексте отп
        // потом запихиваем это в нужные места
        const authenticationsDataFromTarget = dataFromTarget.authentications;
        const otpTextFromTarget = dataFromTarget.push_text.otp;
        this.$set(this.updatedProfile.push_text, 'otp', otpTextFromTarget);
        // преобразуем данные об аутентификациях  в нужный вид
        const currentAuthenticationsDataFromTarget = helpers.transformArrayToObjectByKey(
          authenticationsDataFromTarget,
          'type'
        );
        this.$set(this, 'currentAuthentications', currentAuthenticationsDataFromTarget);

        // // разворачиваем блоки для скопированных настроек
        // const keysForAuthenticationsTypes = Object.keys(this.currentAuthentications);
        // keysForAuthenticationsTypes.forEach(item => {
        //   this.$set(this.openedAuthentications, item, true);
        // });

        // обновляем поле authz_default данными выбранного профиля
        if (Object.prototype.hasOwnProperty.call(dataFromTarget, 'authn_skip')) {
          this.$set(this.updatedProfile, 'authn_skip', dataFromTarget.authn_skip);
        } else {
          this.$set(this.updatedProfile, 'authn_skip', false);
        }
        this.$nextTick(() => {
          this.authNSkipSwitched(this.updatedProfile.authn_skip);
        });
      }
    },
    // тут функции, которые при сворчаиванни блока вызывают валидатор
    // toggleAuthorizationItemVisability(type) {
    //   if (!this.openedAuthorizations[type]) {
    //     Vue.set(this.openedAuthorizations, type, true);
    //   } else {
    //     this.$validator.validateAll().then(
    //       (result) => {
    //         if (result) {
    //           Vue.set(this.openedAuthorizations, type, false);
    //         }
    //       },
    //       () => {
    //         console.warn('Validation failed');
    //       }
    //     );
    //   }
    // },
    // closeAuthorizationItem(type) {
    //   this.$validator.validateAll().then(
    //     (result) => {
    //       if (result) {
    //         if (this.openedAuthorizations[type]) {
    //           Vue.set(this.openedAuthorizations, type, false);
    //         }
    //       }
    //     },
    //     () => {
    //       console.warn('Validation failed');
    //     }
    //   );
    // },
    toggleAuthorizationItemVisability(type) {
      if (!this.openedAuthorizations[type]) {
        Vue.set(this.openedAuthorizations, type, true);
      } else {
        Vue.set(this.openedAuthorizations, type, false);
      }
    },
    closeAuthorizationItem(type) {
      Vue.set(this.openedAuthorizations, type, false);
    },
    openAuthorizationItem(type) {
      Vue.set(this.openedAuthorizations, type, true);
    },
    // тут функции, которые при сворчаиванни блока вызывают валидатор
    // toggleAuthenticationItemVisability(type) {
    //   if (!this.openedAuthentications[type]) {
    //     Vue.set(this.openedAuthentications, type, true);
    //   } else {
    //     this.$validator.validateAll().then(
    //       (result) => {
    //         if (result) {
    //           Vue.set(this.openedAuthentications, type, false);
    //         }
    //       },
    //       () => {
    //         console.warn('Validation failed');
    //       }
    //     );
    //   }
    // },
    // closeAuthenticationItem(type) {
    //   this.$validator.validateAll().then(
    //     (result) => {
    //       if (result) {
    //         if (this.openedAuthentications[type]) {
    //           Vue.set(this.openedAuthentications, type, false);
    //         }
    //       }
    //     },
    //     () => {
    //       console.warn('Validation failed');
    //     }
    //   );
    // },
    toggleAuthenticationItemVisability(type) {
      if (this.isAllAuhNsSwitchersLocked === true) {
        return;
      }
      if (!this.openedAuthentications[type]) {
        Vue.set(this.openedAuthentications, type, true);
      } else {
        Vue.set(this.openedAuthentications, type, false);
      }
    },
    closeAuthenticationItem(type) {
      Vue.set(this.openedAuthentications, type, false);
    },
    openAuthenticationItem(type) {
      Vue.set(this.openedAuthentications, type, true);
    },
    setChooseIntervalShowingForAuthorizations(key, setting) {
      if (!this.chooseIntervalShowingForAuthorizations[key]) {
        // console.log('create!')
        this.$set(this.chooseIntervalShowingForAuthorizations, key, {});
      }
      this.$set(this.chooseIntervalShowingForAuthorizations[key], setting, true);
    },
    setChooseIntervalShowingForAuthorizationsGeneralSettings(setting) {
      // if (!this.chooseIntervalShowingForAuthorizationsGeneral[setting]) {
      //   // console.log('create!')
      //   this.$set(this.chooseIntervalShowingForAuthorizationsGeneral, key, {});
      // }
      this.$set(this.chooseIntervalShowingForAuthorizationsGeneral, setting, true);
    },
    getLocationNameByProfileId(profileId) {
      let result = '';
      if (this.locationsListByProfileId && this.locationsListByProfileId[profileId]) {
        if (this.locationName(this.locationsListByProfileId[profileId])) {
          result = this.locationName(this.locationsListByProfileId[profileId]);
        }
      }
      return result;
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    loadMore() {
      this.offset += 1;
      this.filters.offset = this.offset * this.filters.limit;
      // console.log(this.filters.offset);
      // portalProfilesService.getPortalProfiles(this, { query: true });
      portalProfilesService.getPortalProfiles(this, { query: true, useLocationFilter: true });
    },
    handleCustomTimeAuthentication(type) {
      const { amount, metrics } = this.currentAuthentications[type];
      this.currentAuthentications[type].remember = this.moment(0).add(amount, metrics).unix();
      this.$set(this.chooseIntervalShowingForAuthentication, type, false);
    },
    handleCustomTimeAuthorization(key, setting) {
      const { amount, metrics } = this.currentAuthorizations[key].customTimeInterval[setting];
      this.currentAuthorizations[key].config[setting] = this.moment(0).add(amount, metrics).unix();
      this.$set(this.chooseIntervalShowingForAuthorizations[key], setting, false);
    },
    handleCustomTimeAuthorizationForGeneralSettings(setting) {
      const { amount, metrics } = this.generalAuthZSettings[setting];
      this.generalAuthZSettings[setting].value = this.moment(0).add(amount, metrics).unix();
      this.$set(this.chooseIntervalShowingForAuthorizationsGeneral, setting, false);
    },
    openProfileModal(isNew, Profile) {
      this.showDescriptionInCpesFilter = false;
      this.conditionLocIdShowingBlock = false;
      this.resetGeneralAuthNSettingsToDefault();
      this.resetGeneralAuthZSettingsToDefault();
      this.closeGeneralAuthNSettingsBlock();
      this.closeGeneralAuthZSettingsBlock();
      this.checkAuthNsWhenAuthenSkipIsFalseClearError();
      this.$validator.reset();
      this.redrawSelectorsForSameSettings();
      this.validatorErrors = false;
      this.openedAuthorizations = {};
      this.openedAuthentications = {};
      this.chooseIntervalShowingForAuthentication = {};
      this.chooseIntervalShowingForAuthorizations = {};
      this.isProfileModalOpen = true;
      this.isNewProfile = false;
      this.conditionCpes = [];
      this.conditionWlans = [];
      this.conditionLocId = [];
      if (isNew) {
        this.isNewProfile = true;
        // this.updatedProfileBaseLocation = this.newProfileBaseLocation;
        this.updatedProfile = JSON.parse(JSON.stringify(this.newProfile));
      } else {
        this.updatedProfile = JSON.parse(JSON.stringify(Profile));
        if (!Object.prototype.hasOwnProperty.call(this.updatedProfile, 'authz_default')) {
          this.$set(this.updatedProfile, 'authz_default', '');
        }
        if (!Object.prototype.hasOwnProperty.call(this.updatedProfile, 'authn_skip')) {
          this.$set(this.updatedProfile, 'authn_skip', false);
        }
        this.$set(this.updatedProfile, 'base_location', this.locationsListByProfileId[this.updatedProfile.id]);
        // console.log(this.updatedProfile.base_location);
      }

      if (this.updatedProfile.authn_skip === true) {
        this.lockAllAuhNsSwitchers();
      }

      this.conditionWlans = this.updatedProfile.condition.wlan.reduce((result, wlanId) => {
        const wlan = commonService.wlanObjbyId(wlanId);
        if (wlan) {
          result.push(wlan);
        }
        return result;
      }, []);

      this.conditionCpes = this.updatedProfile.condition.cpe.reduce((result, cpeId) => {
        const cpe = commonService.cpeObjbyId(cpeId);
        if (cpe) {
          result.push(cpe);
        }
        return result;
      }, []);

      if (this.updatedProfile.condition.loc_id === null) {
        this.updatedProfile.condition.loc_id = [];
      }

      this.conditionLocId = this.updatedProfile.condition.loc_id.reduce((result, locId) => {
        const location = commonService.locationObjbyLocId(locId);
        if (location) {
          result.push({ name: location.name, id: location.id, loc_id: location.loc_id });
        }
        return result;
      }, []);
      // console.log(this.conditionLocId)
      this.currentAuthentications = helpers.transformArrayToObjectByKey(this.updatedProfile.authentications, 'type');
      this.currentAuthorizations = helpers.transformArrayToObjectByKey(this.updatedProfile.authorizations, 'type');


      // for (const authZtype in this.currentAuthorizations) {
      //   // console.log(authZtype)
      //   if (Object.prototype.hasOwnProperty.call(this.currentAuthorizations[authZtype], 'config') && typeof this.currentAuthorizations[authZtype].config === 'object') {
      //     if (!Object.prototype.hasOwnProperty.call(this.currentAuthorizations[authZtype].config, 'transferable')) {
      //       this.$set(this.currentAuthorizations[authZtype].config, 'transferable', false)
      //     }
      //   }
      // }

      for (const authZtype in this.currentAuthorizations) {
        // console.log(authZtype)
          if (!Object.prototype.hasOwnProperty.call(this.currentAuthorizations[authZtype], 'transferable')) {
            this.$set(this.currentAuthorizations[authZtype], 'transferable', false)
          }

      }



      // Забираем из ответ сервера TOS, преобразуем в объект и кладем в data
      try {
        this.TOS.text = JSON.parse(this.updatedProfile.terms_of_service.text);
      } catch (e) {
        // console.warn(e);
        this.TOS.text = { [this.TOS.currentLang]: this.updatedProfile.terms_of_service.text };
      }
      [this.selectedTimeZone] = timeZones.filter((el) => el.offset === this.updatedProfile.utc_diff);
      if (!isNew) {
        const keys = Object.keys(this.currentAuthorizations);
        keys.forEach((el) => {
          const list = this.currentAuthorizations;
          list[el].customTimeInterval = {
            timeout: { amount: 1, metrics: 'days' },
            auth_timeout: { amount: 1, metrics: 'days' },
            block_after: { amount: 1, metrics: 'days' },
            block_expire: { amount: 1, metrics: 'days' },
            download_limit: { amount: 1, metrics: 'days' },
            max_sessions: { amount: 1, metrics: 'days' }
          };
        });
        const keysforAuthentications = Object.keys(this.currentAuthentications);
        keysforAuthentications.forEach((el) => {
          const list = this.currentAuthentications;
          list[el].amount = 1;
          list[el].metrics = 'days';
        });
      }
      watcher.reset();
      this.getSocialAuth();
      this.$nextTick(() => {
        this.conditionLocIdShowingBlock = true;
      });
    },
    editProfile(profile) {},
    cancelEditMode() {
      this.isProfileModalOpen = false;
      this.isDeleteOpen = false;
      this.conditionWlans = [];
      this.conditionCpes = [];
      this.conditionLocId = [];
      this.currentAuthentications = {};
      this.currentAuthorizations = {};
      /* Prevents validation blinking during closing animation */
      this.$validator.reset();
    },
    closeConfirmModal() {
      this.isCloseModalOpen = false;
      helpers.handleSecondModalHiding();
    },
    handleConfirmClose() {
      this.closeConfirmModal();
      helpers.handleBothModalsHiding();
      this.cancelEditMode();
    },
    handleModalClick() {
      if (watcher.isChanged) {
        this.isCloseModalOpen = true;
      } else {
        this.cancelEditMode();
      }
    },
    // updateProfile(profile) {
    //   portalProfilesService.updateProfile(this, profile);
    // },
    updateProfile(profile) {
      if (!this.checkAuthNsWhenAuthenSkipIsFalse()) {
        // слишком мало аутентификаций выставляем ошибку
        this.checkAuthNsWhenAuthenSkipIsFalseSetError();
        return;
      }
      this.checkAuthNsWhenAuthenSkipIsFalseClearError();

      // portalProfilesService.updateProfile(this, profile);
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            portalProfilesService.updateProfile(this, profile);
          } else {
            this.validatorErrors = true;
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    createProfile(profile) {
      if (!this.checkAuthNsWhenAuthenSkipIsFalse()) {
        // слишком мало аутентификаций выставляем ошибку
        this.checkAuthNsWhenAuthenSkipIsFalseSetError();
        return;
      }
      this.checkAuthNsWhenAuthenSkipIsFalseClearError();

      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            portalProfilesService.createProfile(this, profile);
          } else {
            this.validatorErrors = true;
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    deleteProfile() {
      portalProfilesService.deleteProfile(this, this.updatedProfile);
    },
    getCpeName(id) {
      return commonService.cpeNamebyId(id);
    },
    getWlanName(id) {
      return commonService.wlanNamebyId(id);
    },
    addPrefix(newPhoneCode, newPrefix) {
      if (!newPhoneCode) {
        return;
      }
      if (!this.updatedProfile.msisdn_config.prefix[newPhoneCode]) {
        Vue.set(this.updatedProfile.msisdn_config.prefix, newPhoneCode, []);
      }

      if (newPrefix) {
        this.updatedProfile.msisdn_config.prefix[newPhoneCode].push(newPrefix);
        this.newPrefix = '';
      }
    },
    removePrefix(code, prefix) {
      if (!this.updatedProfile.msisdn_config.prefix[code] || this.isDisable) {
        return;
      }

      const indexCode = this.updatedProfile.msisdn_config.prefix[code].indexOf(prefix);
      if (indexCode !== -1) {
        this.updatedProfile.msisdn_config.prefix[code].splice(indexCode, 1);
      }

      if (!this.updatedProfile.msisdn_config.prefix[code].length) {
        Vue.delete(this.updatedProfile.msisdn_config.prefix, code);
      }
    },
    setSocialAuth(socialKey, type) {
      switch (type) {
        case 'auth':
          this.currentAuthorizations[socialKey].social_network_config.group_url = '';
          this.currentAuthorizations[socialKey].social_network_config.post.message = '';
          this.currentAuthorizations[socialKey].social_network_config.post.attached_url = '';
          break;
        case 'auth_post':
          this.currentAuthorizations[socialKey].social_network_config.group_url = '';
          this.currentAuthorizations[socialKey].social_network_config.redirect_url = '';
          break;
        case 'auth_group':
          this.currentAuthorizations[socialKey].social_network_config.post.message = '';
          this.currentAuthorizations[socialKey].social_network_config.post.attached_url = '';
          this.currentAuthorizations[socialKey].social_network_config.redirect_url = '';
          break;
        default:
      }
      this.socialAuth[socialKey].type = type;
    },
    getSocialAuth() {
      const socials = Object.keys(this.socialAuth);

      socials.forEach((socialKey) => {
        if (
          this.currentAuthorizations[socialKey] &&
          this.currentAuthorizations[socialKey].social_network_config.group_url
        ) {
          this.socialAuth[socialKey].type = 'auth_group';
          return;
        }
        if (
          this.currentAuthorizations[socialKey] &&
          (this.currentAuthorizations[socialKey].social_network_config.post.message ||
            this.currentAuthorizations[socialKey].social_network_config.post.attached_url)
        ) {
          this.socialAuth[socialKey].type = 'auth_post';
          return;
        }
        if (
          this.currentAuthorizations[socialKey] &&
          this.currentAuthorizations[socialKey].social_network_config.redirect_url
        ) {
          this.socialAuth[socialKey].type = 'auth';
        }
      });
    },
    showAllCpes(cpesList) {
      let cpes = '';
      cpesList.forEach((cpe) => {
        cpes += `<li><small><b></b> ${this.getCpeName(cpe)}</small></li>`;
      });
      return `<ul class='list-unstyled text-left m-0'>
                                              <li class='mb-q'><b>CPEs</b></li>
                                              ${cpes}
                                           </ul>`;
    },
    showAllAds(adsList) {
      let ads = '';
      adsList.forEach((ad) => {
        ads += `<li><small><b></b> ${ad.name}</small></li>`;
      });
      return `<ul class='list-unstyled text-left m-0'>
                                              <li class='mb-q'><b>Ads</b></li>
                                              ${ads}
                                           </ul>`;
    },
    showAllWlans(wlansList) {
      let wlans = '';
      wlansList.forEach((wlan) => {
        wlans += `<li><small><b></b> ${this.getWlanName(wlan)}</small></li>`;
      });
      return `<ul class='list-unstyled text-left m-0'>
                                    <li class='mb-q'><b>WLANs</b></li>
                                    ${wlans}
                                 </ul>`;
    },
    showAllNAS(NASList) {
      let NAS = '';
      NASList.forEach((nas) => {
        NAS += `<li><small><b></b> ${nas}</small></li>`;
      });
      return `<ul class='list-unstyled text-left m-0'>
                                    <li class='mb-q'><b>NAS</b></li>
                                    ${NAS}
                                 </ul>`;
    },
    showAllPrefix(prefixList, code) {
      let prefix = '';
      prefixList.forEach((item) => {
        prefix += `<li><small><b></b> ${item}</small></li>`;
      });
      if (!prefix) {
        prefix += `<li><small><b></b> ${this.$t('portal.allPrefix')}</small></li>`;
      }

      return `<ul class='list-unstyled text-left m-0'>
                                    <li class='mb-q'><b>+${code}</b></li>
                                    ${prefix}
                                 </ul>`;
    },
    showAllAuthorizations(settingsObj) {
      if (settingsObj.type === 'subscription') {
        return '';
      }
      let settings = '';

      for (const key in settingsObj.config) {
        settings += `<li><small><b>${key}:</b> ${settingsObj.config[key]}</small></li>`;
      }

      settings += `<li><small><b>redirect url:</b> ${settingsObj.redirect_url}</small></li>`;
      settings += `<li><small><b>ads:</b> ${settingsObj.ads.length || 'no ads'}</small></li>`;

      return `<ul class='list-unstyled text-left m-0'>
                                    <li class='mb-q'><b>${settingsObj.type}</b></li>
                                    ${settings}
                                 </ul>`;
    },
    showAllAuthentications(settingsObj) {
      let settings = '';

      settings += `<li><small><b>remember:</b> ${settingsObj.remember}</small></li>`;
      settings += `<li><small><b>otp length:</b> ${settingsObj.otp_length}</small></li>`;

      return `<ul class='list-unstyled text-left m-0'>
                                    <li class='mb-q'><b>${settingsObj.type}</b></li>
                                    ${settings}
                                 </ul>`;
    },
    toggleAuthentication(type) {
      // console.log(type)
      this.checkAuthNsWhenAuthenSkipIsFalseClearError();
      if (!this.currentAuthentications[type]) {
        Vue.delete(this.currentAuthentications, type);
      } else {
        // Vue.set(this.currentAuthentications, type, this.portalAuthenticationsList[type]);
        Vue.set(this.currentAuthentications, type, JSON.parse(JSON.stringify(this.portalAuthenticationsList[type])));
        // Здесь создаем два объекта, чтобы использовать быстрый выбор времени.
        Vue.set(this.currentAuthentications[type], 'amount', 1);
        Vue.set(this.currentAuthentications[type], 'metrics', 'days');
        // this.openAuthenticationItem(type);
      }
    },
    toggleAuthorization(type) {
      if (!this.currentAuthorizations[type]) {
        Vue.delete(this.currentAuthorizations, type);
      } else {
        // Vue.set(this.currentAuthorizations, type, this.portalAuthorizationsList[type]);
        Vue.set(this.currentAuthorizations, type, JSON.parse(JSON.stringify(this.portalAuthorizationsList[type])));
        // Здесь создаем два объекта, чтобы использовать быстрый выбор времени.
        Vue.set(this.currentAuthorizations[type], 'customTimeInterval', {
          auth_timeout: { amount: 1, metrics: 'days' },
          block_after: { amount: 1, metrics: 'days' },
          block_expire: { amount: 1, metrics: 'days' },
          block_timeout: { amount: 1, metrics: 'days' },
          timeout: { amount: 1, metrics: 'days' }
        });
        // сразу разварачиваем для возможность настройки
        // this.openAuthorizationItem(type);

        // // тут добавляем ко включаемой авторизации в config свойство transferable, если его нет, и ставим в false,
        // // чтобы потом нормально работала реактивность итд в остальных местах
        // // console.log(this.currentAuthorizations[type]);
        // if (Object.prototype.hasOwnProperty.call(this.currentAuthorizations[type], 'config') && typeof this.currentAuthorizations[type].config === 'object') {
        //   // console.log('here')
        //   if (!Object.prototype.hasOwnProperty.call(this.currentAuthorizations[type].config, 'transferable')) {
        //     Vue.set(this.currentAuthorizations[type].config, 'transferable', false);
        //   }
        // }

        // тут добавляем ко включаемой авторизации  свойство transferable, если его нет, и ставим в false,
        // чтобы потом нормально работала реактивность итд в остальных местах
        // console.log(this.currentAuthorizations[type]);

        if (!Object.prototype.hasOwnProperty.call(this.currentAuthorizations[type], 'transferable')) {
          Vue.set(this.currentAuthorizations[type].config, 'transferable', false);
        }


      }
    },
    getAdStatus(ad) {
      if (!ad.hasOwnProperty('schedule')) {
        return `${this.$t('general.noDataToDisplay')}`;
      }
      const status = getAdStatus(moment().unix(), ad.schedule.start, ad.schedule.stop);
      const statusLocalized = this.$t(`adsprofile.${status}Ad`);
      const pattern = 'DD MMM YYYY';

      switch (status) {
        case 'active':
          return `${statusLocalized} ${this.$t('general.till')} ${moment.unix(ad.schedule.stop).format(pattern)}`;
        case 'planned':
          return `${statusLocalized} ${this.$t('general.from')} ${moment.unix(ad.schedule.start).format(pattern)}`;
        default:
          return statusLocalized;
      }
    },
    ssidWithDescription({ ssid, description }) {
      if (this.isWlanDescription && description) {
        return `${ssid}: ${commonService.cutName(description)}`;
      }
      return `${ssid}`;
    },
    hasCaption(path) {
      return this.$te(`${path}Caption`);
    },
    clearQuery() {
      this.offset = 0;
      this.filters.offset = 0;
      this.canLoadMore = true;
      this.$store.commit('portalProfilesListClean');
    },
    getWithQuery() {
      this.clearQuery();
      // portalProfilesService.getPortalProfiles(this, { query: true });
      portalProfilesService.getPortalProfiles(this, { query: true, useLocationFilter: true });
    },
    getSystemByID(id) {
      const result = this.paymentSystemsList.find((it) => it.id === id);
      return result || {};
    },
    uploadMediaToServer(type) {
      // this[`${type}MuteUpload`] = true;
      const uploadElement = document.querySelector(`.${type}`);
      if (!uploadElement.files.length) {
        return;
      }

      const formData = new FormData();
      formData.append(uploadElement.name, uploadElement.files[0]);
      this.axios.post(`${API_URL || ''}/api/portal/admin/tos/upload`, formData).then(
        (response) => {
          this[`is${helpers.capitalize(type)}Uploaded`] = true;
          this[`${type}MuteUpload`] = false;
          if (type === 'tos' && !this.updatedProfile.terms_of_service.external) {
            this.updatedProfile.terms_of_service.file_url = response.data.data.path;
          }
        },
        (err) => {
          this[`${type}MuteUpload`] = false;
          console.warn(err);
        }
      );
    },
    handleDeleteDialogClose() {
      this.isDeleteOpen = false;
    },
    checkIsShowningRequiredFieldsMsgForSocialNetworkAuthZ(authZName) {
      // тут проверяем показывать ли бейджик для VK и FB авторизаци, напоминающий о необходиоости
      // заполнить обязатлеьные поля redirect_url
      // или post.message и post.attached_url
      // или group_url
      // (в зависимоти от выбраного режима "Авторизация" \ "Авторизация и публикация поста" \ "Авторизация и подписка на группу")
      // если нужно показывать возвращает true, иначе false
      try {
        if (authZName === 'facebook' || authZName === 'vk') {
          return (
            this.checkIsRequiredFieldsForSocialNetworkAuthZAreBlankForTypeAuth(authZName) ||
            this.checkIsRequiredFieldsForSocialNetworkAuthZAreBlankForTypeAuthPost(authZName) ||
            this.checkIsRequiredFieldsForSocialNetworkAuthZAreBlankForTypeAuthPostGroup(authZName)
          );
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    // checkIsRequiredFieldsForSocialNetworkAuthZAreBlank(authZName) {
    //   // тут проверяем заполнены ли обязательные поля для выбраного типа авторизации
    //   // redirect_url
    //   // или post.message и post.attached_url
    //   // или group_url
    //   // (в зависимоти от выбраного режима "Авторизация" \ "Авторизация и публикация поста" \ "Авторизация и подписка на группу")
    //   // если обязательные поля пустые возвращаем true, иначе false
    //   try {
    //     if (Object.prototype.hasOwnProperty.call(this.socialAuth, `${authZName}`) &&
    //       Object.prototype.hasOwnProperty.call(this.socialAuth[`${authZName}`], 'type') &&
    //       Object.prototype.hasOwnProperty.call(this.currentAuthorizations, `${authZName}`) &&
    //       Object.prototype.hasOwnProperty.call(this.currentAuthorizations[`${authZName}`], 'social_network_config')) {
    //       if (this.socialAuth[`${authZName}`].type === 'auth') {
    //         // Если выбран режим Авторизация
    //         if (Object.prototype.hasOwnProperty.call(this.currentAuthorizations[`${authZName}`].social_network_config, 'redirect_url') &&
    //           this.currentAuthorizations[`${authZName}`].social_network_config.redirect_url === '') {
    //           return true;
    //         }
    //       }
    //       if (this.socialAuth[`${authZName}`].type === 'auth_post') {
    //         // Если выбран режим Авторизация и публикация поста
    //         if (Object.prototype.hasOwnProperty.call(this.currentAuthorizations[`${authZName}`].social_network_config, 'post') &&
    //           Object.prototype.hasOwnProperty.call(this.currentAuthorizations[`${authZName}`].social_network_config.post, 'message') &&
    //           Object.prototype.hasOwnProperty.call(this.currentAuthorizations[`${authZName}`].social_network_config.post, 'attached_url') &&
    //           (this.currentAuthorizations[`${authZName}`].social_network_config.post.message === '' || this.currentAuthorizations[`${authZName}`].social_network_config.post.attached_url === '')) {
    //           return true;
    //         }
    //       }
    //       if (this.socialAuth[`${authZName}`].type === 'auth_group') {
    //         // Если выбран режим Авторизация и подписка на группу
    //         if (Object.prototype.hasOwnProperty.call(this.currentAuthorizations[`${authZName}`].social_network_config, 'group_url') &&
    //           this.currentAuthorizations[`${authZName}`].social_network_config.group_url === '') {
    //           return true;
    //         }
    //       }
    //     }
    //     return false;
    //   } catch (e) {
    //     return false;
    //   }
    //
    // },
    checkIsRequiredFieldsForSocialNetworkAuthZAreBlankForTypeAuth(authZName) {
      // тут проверяем заполнены ли обязательные поля для выбраного типа авторизации
      // для выбранного режима "Авторизация"
      // если обязательные поля пустые возвращаем true, иначе false
      try {
        if (
          Object.prototype.hasOwnProperty.call(this.socialAuth, `${authZName}`) &&
          Object.prototype.hasOwnProperty.call(this.socialAuth[`${authZName}`], 'type') &&
          Object.prototype.hasOwnProperty.call(this.currentAuthorizations, `${authZName}`) &&
          Object.prototype.hasOwnProperty.call(this.currentAuthorizations[`${authZName}`], 'social_network_config')
        ) {
          if (this.socialAuth[`${authZName}`].type === 'auth') {
            // Если выбран режим Авторизация
            if (
              Object.prototype.hasOwnProperty.call(
                this.currentAuthorizations[`${authZName}`].social_network_config,
                'redirect_url'
              ) &&
              this.currentAuthorizations[`${authZName}`].social_network_config.redirect_url === ''
            ) {
              return true;
            }
          }
        }

        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    checkIsRequiredFieldsForSocialNetworkAuthZAreBlankForTypeAuthPost(authZName) {
      // тут проверяем заполнены ли обязательные поля для выбраного типа авторизации
      // для выбранного режима "Авторизация и публикация поста"
      // если обязательные поля пустые возвращаем true, иначе false
      try {
        if (
          Object.prototype.hasOwnProperty.call(this.socialAuth, `${authZName}`) &&
          Object.prototype.hasOwnProperty.call(this.socialAuth[`${authZName}`], 'type') &&
          Object.prototype.hasOwnProperty.call(this.currentAuthorizations, `${authZName}`) &&
          Object.prototype.hasOwnProperty.call(this.currentAuthorizations[`${authZName}`], 'social_network_config')
        ) {
          if (this.socialAuth[`${authZName}`].type === 'auth_post') {
            // Если выбран режим Авторизация и публикация поста
            if (
              Object.prototype.hasOwnProperty.call(
                this.currentAuthorizations[`${authZName}`].social_network_config,
                'post'
              ) &&
              Object.prototype.hasOwnProperty.call(
                this.currentAuthorizations[`${authZName}`].social_network_config.post,
                'message'
              ) &&
              Object.prototype.hasOwnProperty.call(
                this.currentAuthorizations[`${authZName}`].social_network_config.post,
                'attached_url'
              ) &&
              (this.currentAuthorizations[`${authZName}`].social_network_config.post.message === '' ||
                this.currentAuthorizations[`${authZName}`].social_network_config.post.attached_url === '')
            ) {
              return true;
            }
          }
        }

        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    checkIsRequiredFieldsForSocialNetworkAuthZAreBlankForTypeAuthPostGroup(authZName) {
      // тут проверяем заполнены ли обязательные поля для выбраного типа авторизации
      // для выбранного режима "Авторизация и подписка на группу"
      // если обязательные поля пустые возвращаем true, иначе false
      try {
        if (
          Object.prototype.hasOwnProperty.call(this.socialAuth, `${authZName}`) &&
          Object.prototype.hasOwnProperty.call(this.socialAuth[`${authZName}`], 'type') &&
          Object.prototype.hasOwnProperty.call(this.currentAuthorizations, `${authZName}`) &&
          Object.prototype.hasOwnProperty.call(this.currentAuthorizations[`${authZName}`], 'social_network_config')
        ) {
          if (this.socialAuth[`${authZName}`].type === 'auth_group') {
            // Если выбран режим Авторизация и подписка на группу
            if (
              Object.prototype.hasOwnProperty.call(
                this.currentAuthorizations[`${authZName}`].social_network_config,
                'group_url'
              ) &&
              this.currentAuthorizations[`${authZName}`].social_network_config.group_url === ''
            ) {
              return true;
            }
          }
        }

        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  },
  // created() {
  //   this.$store.commit('setCpesList', false);
  //   this.$store.commit('portalPaymentSystemsClean');
  //   this.clearQuery();
  //   portalProfilesService.getPortalProfiles(this, { query: true });
  //   portalProfilesService.getAuthentications(this);
  //   portalProfilesService.getAuthorizations(this);
  //   // portalAdsService.getPortalAds(this);
  //   portalAdsService.getPortalAdsWithNoPagination(this);
  //   if (this.paymentSystemsList.length < 1) portalPaymentSystemsService.getPaymentSystems(this);
  //   // if (!this.$store.state.cpesList) {
  //   //   cpeService.getAllCompactCpes(this);
  //   // }
  //   cpeService.getAllCompactCpes(this);
  //   wlanService.getAllWlans(this);
  // },
  created() {
    this.LK_URL = LK_URL;

    this.$store.commit('setCpesList', false);
    this.$store.commit('portalPaymentSystemsClean');
    this.clearQuery();

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.location.with_childs = withChildsForFiltering
      }
    }


    // portalProfilesService.getPortalProfiles(this, { query: true });
    portalProfilesService.getPortalProfiles(this, { query: true, useLocationFilter: true });
    portalProfilesService.getAuthentications(this);
    portalProfilesService.getAuthorizations(this);
    // portalAdsService.getPortalAds(this);
    portalAdsService.getPortalAdsWithNoPagination(this);
    if (this.paymentSystemsList.length < 1) portalPaymentSystemsService.getPaymentSystems(this);
    // if (!this.$store.state.cpesList) {
    //   cpeService.getAllCompactCpes(this);
    // }
    // cpeService.getAllCompactCpes(this);
    cpeService.getAllCompactCpesWithDataRemaining(this);
    // wlanService.getAllWlans(this);
    wlanService.getAllCompactWlansWithDataRemainingWithoutClients(this);
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cpes-show-description {
  /*font-size: 0.7em;*/
}
.social-auth-required-fields-not-forget-info-msg-custom-color {
  background-color: #f8cb00;
}
.authZ-settings-fields-height-hack {
  min-height: 2.7em !important;
  line-height: 97% !important;
}

.portal-profiles-interval-select-buttons {
  font-size: 0.9em !important;
  border-right: 0.5px;
  border-left: 0.5px;
  border-style: solid;
  border-color: rgba(236, 236, 236, 0.45);
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -0.5px;
}

.default-authZ-caption {
  font-weight: 500;
}
.counter-access-list-in-portal-profiles {
  height: 2.2rem !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
/* .card {
  min-width: 900px;
} */

.authorization-block {
  border-bottom: 1px solid #cdd6e4;
}

.authorization-block:last-child {
  border-bottom: 0;
}

.authentication-block:not(:last-of-type) {
  border-bottom: 1px solid #cdd6e4;
}

.payment-logo-preview {
  width: 70px;
  height: 50px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #ffffff;
  border: 1px solid #acacac;
}
.half-width {
  width: 48%;
}

.third-width {
  width: 37%;
}

.input-small input {
  width: 80%;
}

.input-small-for-otp-length input {
  width: 50%;
}

.two-thirds-width {
  width: 55%;
}

.fourth-width {
  width: 22%;
}

.three-quarter-width {
  width: 72%;
}

.one-six-width {
  width: 10%;
}

.column-one-for-modal-layout {
  width: 44%;
}
.column-two-for-modal-layout {
  width: 52%;
}
.full-width {
  width: 100%;
}

.loader-backdrop-portalprofiles:after {
  position: absolute;
  top: 30px;
}
.tos-inherit-background div {
  background-color: inherit !important;
  border-width: 0px !important;
  box-shadow: inherit !important;
}
.tos-inherit-background .interface-block {
  margin-top: 0px !important;
  padding-top: 5px !important;
}
.clear-margin-top {
  margin-top: 0px !important;
}
.authorization-categories {
  font-size: 1.3em !important;
  margin-bottom: 1em;
  margin-top: 1em;
  font-weight: 500;
}
.notification-settings-in-category {
  padding-left: 2em;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.data-iterator-data-categories {
  font-size: 1.1em;
  font-weight: 500;
}
.custom-padding-bottom-for-description {
  padding-bottom: 0.25em;
}
.custom-padding-for-in-iterator-card-data-items {
  padding-left: 0.5em;
}
.settings-button-for-authn-and-authz {
  font-size: 0.8em;
  cursor: pointer;
}
.cutDownButton {
  padding-bottom: 2em;
  padding-top: 2em;
}

.div-for-settings-button-for-authn-and-authz {
  display: inline;
  margin-left: 1em;
}

.validatorErrors {
  color: red;
}
</style>

<style>
.authentication-list-in-portal-profiles-locked {
  background: rgba(0, 0, 0, 0.04);
  color: gray !important;
  cursor: not-allowed !important;
}

.authentication-list-in-portal-profiles-locked .switch-input:checked ~ .switch-label {
  background: rgba(40, 40, 40, 0.2) !important;
  color: gray !important;
}
.authentication-list-in-portal-profiles-locked-settings-button-for-authn {
  cursor: not-allowed !important;
}
.authentication-general-settings-in-portal-profiles-locked {
  color: rgba(40, 40, 40, 0.2) !important;
  cursor: not-allowed !important;
}

.multiselect-max-height-in-profiles-modal .multiselect__tags {
  max-height: 200px !important;
  padding: 7px !important;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.multiselect-max-height-in-profiles-modal .multiselect__tags::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f1f1f1;
}

.multiselect-max-height-in-profiles-modal .multiselect__tags::-webkit-scrollbar-thumb {
  background: lightgray;
}
.multiselect-max-height-in-profiles-modal .multiselect__content-wrapper {
  overflow-x: hidden !important;
  min-width: 500px;
  width: fit-content !important;
  text-align: left;
  /*    overflow-x: hidden;*/
  /*text-overflow: ellipsis;*/
}
</style>
